var game = null;

var quiz = (json) => {
    var gameData = {
        score: 0,
        winSound: null,
        correctQ: 0,
        wrongQ: 0,
        totalQ: 0,
        progress: 0,

        reset: function () {
            this.correctQ = 0;
            this.wrongQ = 0;
            this.totalQ = 0;
            this.progress = 0;
            this.score = 0;
            this.winSound = null;
        }
    };

    class Option extends Phaser.GameObjects.Sprite {
        constructor(config) {
            super(config.scene, config.x, config.y, config.key);

            this.origX = config.x;
            this.origY = config.y;
            this.id = config.id;
            this.string = config.string;
            this.imageKey = config.imageKey;
            this.setOrigin(0.5, 0.5);
            this.setInteractive();
            this.correct = false;
            config.scene.add.existing(this);

            var textStyle = {
                fontSize: "65px", fontFamily: "QuranEraV3", align: "center",
                fill: "#000"
            };

            this.stringText = this.scene.add.text(config.x + 12, config.y, "", textStyle);
            this.stringText.setOrigin(0.5, 0.5);
            //this.stringText.setDisplaySize(100, 100);
            this.stringText.setVisible(false);
            this.stringText.setPadding(0, 40, 0, 0)
            this.stringText.setFixedSize(345, 154);
            this.img = this.scene.add.sprite(config.x + 12, config.y, "");
            this.img.setOrigin(0.5, 0.5);
            this.img.setVisible(false);

            this.on('pointerdown', function () {
            });
        }

        update() {
        }

        setString(text, correct) {
            this.stringText.setText(text);
            this.stringText.setVisible(true);
            this.img.setVisible(false);
            this.correct = correct;
        }

        setImg(img, correct) {
            this.img.setTexture(img);
            this.img.setVisible(true);
            this.stringText.setVisible(false);
            this.correct = correct;
        }
    }

    class DropZone extends Phaser.GameObjects.Sprite {
        constructor(config) {
            super(config.scene, config.x, config.y, config.key);

            this.id = config.id;
            this.setOrigin(0.5, 0.5);

            config.scene.add.existing(this);

            this.dropRect = config.scene.add.image(this.x, this.y + 110, 'dropRect');
            this.dropRect.setOrigin(0.5, 0.5);
        }

        setPos(x, y) {
            this.x = x;
            this.y = y;
            this.dropRect.x = x;
            this.dropRect.y = y + 110;
        }
    }

    class Boot extends Phaser.Scene {
        constructor() {
            super({ key: "Boot" });
        }

        preload() {
            var instance = this;
            var progress = this.add.graphics();
            this.load.on('progress', function (value) {
            });

            this.load.on('complete', function () {
                progress.destroy();
                this.scene.scene.start('Welcome');
            });

            //this.load.start();

            //load all assets here

            /*WELCOME SCENE ASSETS*/
            //this.load.image("frame", "assets/games/quiz/assets/Frame.png");
            this.load.image("greyRect", "assets/games/quiz/assets/welcome/greyRect.png");
            this.load.image("instRect", "assets/games/quiz/assets/welcome/instRect.png");
            this.load.image("title", "assets/games/quiz/assets/welcome/welcomeText.png");
            this.load.image("playBtn", "assets/games/quiz/assets/welcome/playBtn.png");
            this.load.image("playBtnselected", "assets/games/quiz/assets/welcome/playBtnselected.png");
            this.load.image("soundBtn", "assets/games/quiz/assets/welcome/soundBtn.png");
            this.load.image("soundBtnHover", "assets/games/quiz/assets/welcome/soundBtnHover.png");
            this.load.image("snail", "assets/games/quiz/assets/welcome/snail.png");

            /*GAME SCENE ASSETS*/
            this.load.image("gamebg", "assets/games/quiz/assets/game/boardbg.png");
            this.load.image("playBoard", "assets/games/quiz/assets/game/playBoard.png");
            this.load.image("titleRect", "assets/games/quiz/assets/game/titleRect.png");
            this.load.image("optionBg", "assets/games/quiz/assets/game/optionBg.png");
            this.load.image("optionBgHover", "assets/games/quiz/assets/game/optionBgHover.png");
            this.load.image("optionBgCorrect", "assets/games/quiz/assets/game/optionCorrect.png");
            this.load.image("optionBgWrong", "assets/games/quiz/assets/game/optionWrong.png");
            this.load.image("gamesoundBtn", "assets/games/quiz/assets/game/sound.png");
            this.load.image("gamesoundBtnHover", "assets/games/quiz/assets/game/soundhover.png");
            this.load.image("qBg", "assets/games/quiz/assets/game/qBg.png");
            this.load.image("closeBtn", "assets/games/quiz/assets/game/closeBtn.png");
            this.load.image("closeBtnHover", "assets/games/quiz/assets/game/closeBtnHover.png");
            this.load.image("woodbar", "assets/games/quiz/assets/game/Wood.png");
            this.load.image("woodbrown", "assets/games/quiz/assets/game/woodbrown.png");
            this.load.image("bluebar", "assets/games/quiz/assets/game/bluebar.png");

            /*TRY AGAIN SCENE ASSETS*/
            this.load.image("looseBg", "assets/games/quiz/assets/loose/looseBg.png");
            //this.load.image("playagainBtn", "assets/games/quiz/assets/loose/playagainBtn.png");
            //this.load.image("playagainBtnHover", "assets/games/quiz/assets/loose/playagainBtnHover.png");
            this.load.image("backtoMenu", "assets/games/quiz/assets/loose/backtomenu.png");
            this.load.image("backtoMenuHover", "assets/games/quiz/assets/loose/backtomenuHover.png");
            this.load.image("badge", "assets/games/quiz/assets/loose/badge.png");
            this.load.image("bigStar", "assets/games/quiz/assets/loose/bigstar.png");
            this.load.image("ribbon", "assets/games/quiz/assets/loose/ribbon.png");
            this.load.image("totalScoreText", "assets/games/quiz/assets/loose/totalScoreText.png");
            this.load.image("tryagainBoard", "assets/games/quiz/assets/loose/tryagainBoard.png");
            this.load.image("tryagain", "assets/games/quiz/assets/loose/tryagain.png");

            /*WIN SCREEN ASSETS */
            this.load.image("certificate", "assets/images/awards/cert-200.jpg");
            this.load.image("congratsTitle", "assets/games/quiz/assets/win/congratsTitle.png");
            this.load.image("playagainBtn", "assets/games/quiz/assets/win/playagain.png");
            this.load.image("playagainBtnHover", "assets/games/quiz/assets/win/playagainHover.png");
            this.load.image("nextBtn", "assets/games/quiz/assets/win/nextBtn.png");
            this.load.image("nextBtnHover", "assets/games/quiz/assets/win/nextBtnHover.png");

            /* WARNING SCREEN ASSETS*/
            this.load.image("noBtn", "assets/games/quiz/assets/warning/noBtn.png");
            this.load.image("noBtnHover", "assets/games/quiz/assets/warning/noBtnHover.png");
            this.load.image("yesBtn", "assets/games/quiz/assets/warning/yesBtn.png");
            this.load.image("yesBtnHover", "assets/games/quiz/assets/warning/yesBtnHover.png");
            this.load.image("sign", "assets/games/quiz/assets/warning/sign.png");
            this.load.image("warningBg", "assets/games/quiz/assets/warning/warningbg.png");
            this.load.image("warningBoard", "assets/games/quiz/assets/warning/warningboard.png");
            this.load.image("leaf", "assets/games/quiz/assets/warning/leaf.png");

            /*LOAD SOUNDS*/
            this.load.audio('quiz1Intro', [json.introduction.audio]);
            this.load.audio('wrongDrop', 'assets/games/quiz/assets/audio/wrongdrop.mp3');
            this.load.audio('correctDrop', 'assets/games/quiz/assets/audio/correctdrop.mp3');
            this.load.audio('fail', ['assets/games/quiz/assets/audio/fail.mp3', 'assets/games/quiz/assets/audio/fail.mp3']);

            this.load.audio('buttonclick', 'assets/games/quiz/assets/audio/buttonclick.mp3');
            this.load.audio('buttonhover', 'assets/games/quiz/assets/audio/buttonhover.mp3');
            this.load.audio('win', [json.completeAudio]);
            this.load.audio('correctAnswer', ['assets/games/quiz/assets/audio/Correctanswer.mp3', 'assets/games/quiz/assets/audio/Correctanswer.mp3']);
            this.load.audio('wrongAnswer', ['assets/games/quiz/assets/audio/Wronganswer.mp3', 'assets/games/quiz/assets/audio/Wronganswer.mp3']);
            this.load.audio('warning', ['assets/games/quiz/assets/audio/warning.mp3', 'assets/games/quiz/assets/audio/warning.mp3']);
            this.load.json('data', json);
        }

        create() {
        }

        update() {
        }

        init() {
        }
    }

    var welcomeInstance = null;

    var instructionSound = null;

    class Welcome extends Phaser.Scene {
        constructor() {
            super({ key: "Welcome" });

            welcomeInstance = this;
        }

        preload() {
            let catalog = this.cache.json.get('data');

            catalog.data.map((question, i) => {
                if (question.audio.includes('http')) {
                    this.load.audio("Q" + i + "Audio", [question.audio])
                } else {
                    this.load.audio("Q" + i + "Audio", ["assets/games/quiz/assets/audios/" + question.audio + ".mp3"]);
                }

                question.answers.map((answer, j) => {
                    if (answer.text.includes('http')) {
                        this.load.image("Q" + (i + 1) + "_op" + (j + 1), answer.text);
                    } else {
                        if (answer.image) {
                            this.load.image("Q" + (i + 1) + "_op" + (j + 1), "assets/games/quiz/assets/imgs/" + answer.text + ".png");
                        }
                    }
                })
            });
        }

        create() {
            this.buttonClick = this.sound.add('buttonclick');
            this.buttonHover = this.sound.add('buttonhover');

            instructionSound = this.sound.add("quiz1Intro");// document.getElementById("instAudio");
            instructionSound.play();

            //var board = this.add.sprite(this.game.config.width/2, this.game.config.height/2, 'board');
            //board.setOrigin(0.5, 0.5);

            var greyRect = this.add.sprite(this.game.config.width / 2 + 6.5, this.game.config.height / 2 - 25.5, 'greyRect');
            greyRect.setOrigin(0.5, 0.475);

            var board = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2, 'frame');
            board.setOrigin(0.5, 0.5);

            var instRect = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 - 30, 'instRect');
            instRect.setOrigin(0.5, 0.53);

            var snail = this.add.sprite(this.game.config.width / 2 - 220, this.game.config.height / 2, 'snail');
            snail.setOrigin(0.5, 0.5);
            var title = this.add.text(this.game.config.width / 2, this.game.config.height / 2 - 200, `${json.continent} - ${json.title}`, { fontFamily: 'Kids Rock DEMO', fontSize: 32, color: '#3280db', align: 'center', strokeThickness: 3 });
            title.setOrigin(0.5, 0.9);

            // var title = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 - 205, 'title');
            // title.setOrigin(0.5, 0.9);
            //title.setScale(0.8);

            var playBtn = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 + 160, 'playBtn').setInteractive();
            playBtn.setOrigin(0.5, 0.5);

            var tween = this.tweens.add({
                targets: playBtn,
                scale: 0.8,
                duration: 100,
                ease: 'Power2',
                yoyo: true,
                paused: true
            });

            var tween1 = this.tweens.add({
                targets: playBtn,
                scale: 1.1,
                duration: 100,
                ease: 'Power2',
                paused: true,
            });

            playBtn.on('pointerdown', function () {
                this.scene.scene.start("Game");
                this.scene.buttonClick.play();
            });

            playBtn.on('pointerover', function () {
                playBtn.setTexture("playBtnselected");
                this.scene.buttonHover.play();
            });

            playBtn.on('pointerout', function () {
                playBtn.setTexture("playBtn");
            });

            var quizId = window.location.pathname.split('/').pop();
            var textWelcome = [
                (
                    quizId === '1-4' || quizId === '2-3' || quizId === '3-3'
                        ? "This quiz has 15 questions.\n\nYou need to pass this quiz to \n\nget through to the next continent.\n\nGood Luck!"
                        : "This quiz has 15 questions.\n\nYou need to pass this quiz to \n\nget through to the next zone.\n\nGood Luck!"
                )
            ];

            var paragraph = this.add.text(this.game.config.width / 2 + 130, this.game.config.height / 2, textWelcome,
                {
                    fontFamily: "Kids Rock DEMO",
                    fontSize: 23,
                    color: '#312f2f',
                    align: 'left',
                    strokeThickness: 1
                });

            paragraph.setOrigin(0.5, 0.5);

            var soundBtn = this.add.sprite(this.game.config.width / 2 + 310, this.game.config.height / 2 - 210, 'gamesoundBtn').setInteractive();
            soundBtn.setOrigin(0.5, 0.65);
            soundBtn.setScale(0.8)

            soundBtn.on("pointerover", function () {
                soundBtn.setTexture('gamesoundBtnHover');
                this.scene.buttonHover.play();
            })

            soundBtn.on("pointerout", function () {
                soundBtn.setTexture('gamesoundBtn');
            })

            soundBtn.on('pointerdown', function () {
                this.scene.buttonClick.play();

                instructionSound.play();
            })
        }

        update(time, delta) {
        }
    }

    var gameInstance = null;

    const clampNumber = (num, a, b) => Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b));

    class Game extends Phaser.Scene {
        constructor() {
            super({ key: "Game" });
            gameInstance = this;
        }

        preload() {
        }

        create() {
            gameData.reset();

            this.questionCompleted = false;

            this.questionIndex = 1;

            this.currentQuestion = null;

            this.progressX = 0;

            this.questions = [];

            if (instructionSound != null) {
                instructionSound.stop();
            }

            this.correctAnsSound = this.sound.add("correctAnswer");
            this.wrongAnsSound = this.sound.add("wrongAnswer");

            this.incorrect = 0;
            this.correct = 0;

            var btnclickSound = this.sound.add('buttonclick');
            var btnhoverSound = this.sound.add('buttonhover');

            this.wrongSound = this.sound.add('wrongDrop');
            this.correctSound = this.sound.add('correctDrop');

            var gameBg = this.add.sprite(this.game.config.width / 2 + 6.5, this.game.config.height / 2 - 25, 'gamebg');
            gameBg.setOrigin(0.5, 0.475);

            var playBoard = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 - 20, 'playBoard');
            playBoard.setOrigin(0.5, 0.5);

            //var board = this.add.sprite(this.game.config.width/2, this.game.config.height/2, 'board');
            //board.setOrigin(0.5, 0.5);

            var titleRect = this.add.sprite(this.game.config.width / 2, -25, 'titleRect');
            titleRect.setOrigin(0.5, 0);

            var title = this.add.text(this.game.config.width / 2, 35, json.title, {
                fontFamily: 'Kids Rock DEMO',
                fontSize: 28,
                color: '#ffffff',
                stroke: '#ffffff',
                align: 'center',
                strokeThickness: 1
            });
            title.setOrigin(0.5, 0.2);

            var qBg = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 - 140, 'qBg');
            qBg.setOrigin(0.5, 0.5);

            this.question = this.add.text(this.game.config.width / 2 - 280, this.game.config.height / 2 - 135, "Question 1.", {
                fontFamily: "Kids Rock DEMO",
                fontSize: 22,
                color: '#000',
                stroke: '#000',
                align: 'left'
            });
            this.question.fixedHeight = 50;
            this.question.setOrigin(0.5, 0.5);

            this.option1 = new Option({
                scene: this,
                x: game.config.width / 2 - 180,
                y: game.config.height / 2 - 20,
                string: "A",
                imageKey: "",
                id: 1,
                key: "optionBg"
            });

            this.option2 = new Option({
                scene: this,
                x: game.config.width / 2 + 160,
                y: game.config.height / 2 - 20,
                string: "A",
                imageKey: "",
                id: 1,
                key: "optionBg"
            });

            this.option3 = new Option({
                scene: this,
                x: game.config.width / 2 - 180,
                y: game.config.height / 2 + 120,
                string: "A",
                imageKey: "",
                id: 1,
                key: "optionBg"
            });

            this.option4 = new Option({
                scene: this,
                x: game.config.width / 2 + 160,
                y: game.config.height / 2 + 120,
                string: "A",
                imageKey: "",
                id: 1,
                key: "optionBg"
            });

            this.option1.on("pointerdown", this.onOptionSelect);
            this.option2.on("pointerdown", this.onOptionSelect);
            this.option3.on("pointerdown", this.onOptionSelect);
            this.option4.on("pointerdown", this.onOptionSelect);

            this.option1.on("pointerover", function () {
                if (!this.scene.questionCompleted) {
                    this.setTexture('optionBgHover');
                }
            });

            this.option2.on("pointerover", function () {
                if (!this.scene.questionCompleted) {
                    this.setTexture('optionBgHover');
                }
            });
            this.option3.on("pointerover", function () {
                if (!this.scene.questionCompleted) {
                    this.setTexture('optionBgHover');
                }
            });
            this.option4.on("pointerover", function () {
                if (!this.scene.questionCompleted) {
                    this.setTexture('optionBgHover');
                }
            });

            this.option1.on("pointerout", function () {
                if (!this.scene.questionCompleted) {
                    this.setTexture('optionBg');
                }
            });

            this.option2.on("pointerout", function () {
                if (!this.scene.questionCompleted) {
                    this.setTexture('optionBg');
                }
            });
            this.option3.on("pointerout", function () {
                if (!this.scene.questionCompleted) {
                    this.setTexture('optionBg');
                }
            });
            this.option4.on("pointerout", function () {
                if (!this.scene.questionCompleted) {
                    this.setTexture('optionBg');
                }
            });

            var closeBtn = this.add.sprite(this.game.config.width - 60, 40, 'closeBtn').setInteractive();
            closeBtn.setOrigin(0.5, 0.5);

            closeBtn.on("pointerdown", function () {
                btnclickSound.play();
                this.scene.questions[this.scene.questionIndex - 1].sound.stop();
                this.scene.scene.launch('Warning');

                this.scene.scene.pause('Game');
            })

            closeBtn.on("pointerover", function () {
                btnhoverSound.play();
                closeBtn.setTexture('closeBtnHover');
            })

            closeBtn.on("pointerout", function () {
                closeBtn.setTexture('closeBtn');
            })

            var soundBtn = this.add.sprite(this.game.config.width / 2 + 360, this.game.config.height / 2 - 120, 'gamesoundBtn').setInteractive();
            soundBtn.setOrigin(0.5, 0.5);

            soundBtn.on("pointerdown", function () {
                btnclickSound.play();
                this.scene.questions[this.scene.questionIndex - 1].sound.play();
                //document.getElementById("Q"+this.scene.questionIndex+"Audio").play();
            })

            soundBtn.on("pointerover", function () {
                btnhoverSound.play();
                soundBtn.setTexture('gamesoundBtnHover');
            })

            soundBtn.on("pointerout", function () {
                soundBtn.setTexture('gamesoundBtn');
            })

            var woodbase = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 + 260, 'woodbar');
            woodbase.setOrigin(0.5, 0.5);

            var woodbrown = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 + 260, 'woodbrown');
            woodbrown.setOrigin(0.5, 0.5);

            this.bluebar = this.add.sprite(this.game.config.width / 2 - 749, this.game.config.height / 2 + 260, 'bluebar');
            this.bluebar.setOrigin(0.5, 0.5);

            var shape = this.make.graphics();

            //  Create a hash shape Graphics object
            shape.fillStyle(0x000000);

            //  You have to begin a path for a Geometry mask to work
            shape.beginPath();

            shape.fillRect(120, this.game.config.height / 2 + 245, 741, 31);

            var mask = shape.createGeometryMask();

            this.bluebar.setMask(mask);

            this.createQuestions();
            this.SetQuestionOnBoard(this.questionIndex);
        }

        update(time, delta) {
        }

        shuffle(arra1) {
            var ctr = arra1.length, temp, index;

            // While there are elements in the array
            while (ctr > 0) {
                // Pick a random index
                index = Math.floor(Math.random() * ctr);
                // Decrease ctr by 1
                ctr--;
                // And swap the last element with it
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        }

        createQuestions() {
            var option = {
                id: 0,
                string: "",
                img: "",
                correct: false
            }

            var question = {
                id: 0,
                string: "",
                options: [],
            }

            var catalog = this.cache.json.get('data');

            catalog.data.map((question, i) => {
                var q = { id: 0, string: "", options: [], sound: null };

                q.id = i;
                q.string = question.question;
                q.sound = this.sound.add(`Q${i}Audio`);

                question.answers.map((answer, j) => {
                    let op = { id: 0, string: "", img: "", correct: false };
                    op.id = j;
                    op.img = (answer.text.includes('http') || answer.image) ? `Q${i + 1}_op${j + 1}` : '';
                    op.string = !answer.text.includes('http') ? answer.text : ''
                    op.correct = answer.correct

                    q.options.push(op);
                })
                this.questions.push(q);
            });

            gameData.totalQ = this.questions.length;

            gameData.progress = 738 / gameData.totalQ;
        }

        SetQuestionOnBoard(index) {
            if (this.prevQuestionSound) {
                this.prevQuestionSound.stop();
            }

            if (index > this.questions.length) {
                if (gameData.wrongQ > 4)
                    this.scene.start("Loose");
                else {
                    this.scene.start("Win");
                }

                return;
            }

            this.option1.setTexture("optionBg");
            this.option2.setTexture("optionBg");
            this.option3.setTexture("optionBg");
            this.option4.setTexture("optionBg");

            this.questionCompleted = false;
            var question = this.questions[index - 1];

            question.options = this.shuffle(question.options);

            if (question.options[0].img != "") {
                this.option1.setImg(question.options[0].img, question.options[0].correct);
            } else {
                this.option1.setString(question.options[0].string, question.options[0].correct);
            }

            if (question.options[1].img != "") {
                this.option2.setImg(question.options[1].img, question.options[1].correct);
            } else {
                this.option2.setString(question.options[1].string, question.options[1].correct);
            }

            if (question.options[2].img != "") {
                this.option3.setImg(question.options[2].img, question.options[2].correct);
            } else {
                this.option3.setString(question.options[2].string, question.options[2].correct);
            }

            if (question.options[3].img != "") {
                this.option4.setImg(question.options[3].img, question.options[3].correct);
            } else {
                this.option4.setString(question.options[3].string, question.options[3].correct);
            }

            for (var i = 0; i < question.options.length; i++) {
                if (question.options[i].correct) {
                    switch (i) {
                        case 0:
                            this.option1.correct = true;
                            break;
                        case 1:
                            this.option2.correct = true;
                            break;
                        case 2:
                            this.option3.correct = true;
                            break;
                        case 3:
                            this.option4.correct = true;
                            break;
                    }
                }
            }

            this.question.setText("Question " + (question.id + 1) + ".");
            question.sound.play();

            this.prevQuestionSound = question.sound;
        }

        onOptionSelect() {
            if (!this.scene.questionCompleted) {
                this.scene.questionCompleted = true;

                if (this.correct) {
                    this.setTexture('optionBgCorrect');
                    this.scene.correctAnsSound.play();
                    gameData.correctQ += 1;
                    window['angularComponentRef'].changeCorrectAnswer(gameData.correctQ);
                } else {
                    this.setTexture('optionBgWrong');
                    this.scene.wrongAnsSound.play();
                    gameData.wrongQ += 1;
                }

                this.scene.time.addEvent({
                    delay: 2000,
                    callback: () => {
                        this.scene.questionIndex += 1;
                        this.scene.SetQuestionOnBoard(this.scene.questionIndex);
                        var barx = this.scene.bluebar.x + gameData.progress;
                        //this.scene.bluebar.setX(barx);
                        this.scene.bartween = this.scene.tweens.add({
                            targets: this.scene.bluebar,
                            x: barx,
                            duration: 500,
                            ease: 'Power2',
                        });
                    },
                    loop: false
                });
            }
        }

        shuffle(arra1) {
            var ctr = arra1.length, temp, index;

            // While there are elements in the array
            while (ctr > 0) {
                // Pick a random index
                index = Math.floor(Math.random() * ctr);
                // Decrease ctr by 1
                ctr--;
                // And swap the last element with it
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        }
    }

    class Loose extends Phaser.Scene {
        constructor() {
            super({ key: "Loose" });
        }

        preload() {
        }

        create() {
            window.angularComponentRef.gameFinishWithLoose();

            this.buttonClick = this.sound.add('buttonclick');
            this.buttonHover = this.sound.add('buttonhover');

            var failSound = this.sound.add("fail");// document.getElementById("instAudio");
            failSound.play();

            //var board = this.add.sprite(this.game.config.width/2, this.game.config.height/2, 'board');
            //board.setOrigin(0.5, 0.5);

            var greyRect = this.add.sprite(this.game.config.width / 2 + 6.5, this.game.config.height / 2 - 25.5, 'greyRect');
            greyRect.setOrigin(0.5, 0.475); var greyRect = this.add.sprite(this.game.config.width / 2 + 6.5, this.game.config.height / 2 - 25.5, 'greyRect');
            greyRect.setOrigin(0.5, 0.475);

            var board = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2, 'frame');
            board.setOrigin(0.5, 0.5)

            var instRect = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 - 30, 'instRect');
            instRect.setOrigin(0.5, 0.53);

            var bigStar = this.add.sprite(this.game.config.width / 2 - 180, this.game.config.height / 2 + 30, 'bigStar');
            bigStar.setOrigin(0.5, 0.5);
            bigStar.setScale(0);

            var tween = this.tweens.add({
                targets: bigStar,
                scale: 1,
                ease: 'Power1',
                duration: 500,
            });
            var title = this.add.text(this.game.config.width / 2, this.game.config.height / 2 - 200, `${json.continent} - ${json.title}`, { fontFamily: 'Kids Rock DEMO', fontSize: 32, color: '#3280db', align: 'center', strokeThickness: 3 });
            title.setOrigin(0.5, 0.9);

            var playBtn = this.add.sprite(this.game.config.width / 2 + 190, this.game.config.height / 2 + 225, 'playagainBtn').setInteractive();
            playBtn.setOrigin(0.5, 0.5);

            var backbtn = this.add.sprite(this.game.config.width / 2 + 40, this.game.config.height / 2 + 225, 'backtoMenu').setInteractive();
            backbtn.setOrigin(0.5, 0.5);

            var tween = this.tweens.add({
                targets: playBtn,
                scale: 0.8,
                duration: 100,
                ease: 'Power2',
                yoyo: true,
                paused: true
            });

            var tween1 = this.tweens.add({
                targets: playBtn,
                scale: 1.1,
                duration: 100,
                ease: 'Power2',
                paused: true,
            });

            playBtn.on('pointerdown', function () {
                failSound.stop();

                this.scene.scene.start("Welcome");
                this.scene.buttonClick.play();
            });

            playBtn.on('pointerover', function () {
                playBtn.setTexture("playagainBtnHover");
                this.scene.buttonHover.play();
            });

            playBtn.on('pointerout', function () {
                playBtn.setTexture("playagainBtn");
            });

            backbtn.on('pointerdown', function () {
                failSound.stop();

                window.angularComponentRef.backToMenu();
            });

            backbtn.on('pointerover', function () {
                backbtn.setTexture("backtoMenuHover");
                this.scene.buttonHover.play();
            });

            backbtn.on('pointerout', function () {
                backbtn.setTexture("backtoMenu");
            });

            var congratsTitle = this.add.sprite(this.game.config.width / 2 + 60, this.game.config.height / 2 - 80, 'tryagain');
            congratsTitle.setOrigin(0.5, 0.5);

            var content = [
                "This time you didn't pass",
                "the quiz."

            ];

            var paragraph = this.add.text(this.game.config.width / 2 + 130, this.game.config.height / 2, content, {
                fontFamily: "Kids Rock DEMO",
                fontSize: 23,
                color: '#312f2f',
                align: 'left'
            });
            paragraph.setOrigin(0.5, 0.5);
            paragraph.setLineSpacing(10);

            var paragraph1 = this.add.text(this.game.config.width / 2 + 130, this.game.config.height / 2 + 100, "You have answered " + gameData.correctQ + " out \nof " + gameData.totalQ + " questions correctly.", {
                fontFamily: "Kids Rock DEMO",
                fontSize: 23,
                color: '#312f2f',
                align: 'left'
            });
            paragraph1.setOrigin(0.5, 0.5);
            paragraph1.setLineSpacing(10);
        }

        update(time, delta) {
        }
    }

    class Warning extends Phaser.Scene {
        constructor() {
            super({ key: "Warning" });
        }

        preload() {
        }

        create() {
            var btnclickSound = this.sound.add('buttonclick');
            var btnhoverSound = this.sound.add('buttonhover');

            var warningsound = this.sound.add("warning");
            warningsound.play();

            var bg = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2, 'warningBg');
            bg.setOrigin(0.494, 0.516);

            var leaf = this.add.sprite(this.game.config.width / 2 - 380, this.game.config.height / 2 + 130, 'leaf');
            leaf.setOrigin(0.5, 0.5);

            var leaf1 = this.add.sprite(this.game.config.width / 2 + 380, this.game.config.height / 2 - 130, 'leaf');
            leaf1.setOrigin(0.5, 0.5);
            leaf1.setFlipX(-1);

            var board = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2, 'warningBoard');
            board.setOrigin(0.5);

            var sign = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 - 130, 'sign');
            sign.setOrigin(0.5);

            var yesBtn = this.add.sprite(this.game.config.width / 2 - 100, this.game.config.height / 2 + 150, 'yesBtn').setInteractive();
            yesBtn.setOrigin(0.5);

            yesBtn.on("pointerdown", function () {
                btnclickSound.play();
                this.scene.scene.stop('Warning');
                this.scene.scene.stop('Game');
                window.angularComponentRef.backToMenu();
            });

            yesBtn.on("pointerover", function () {
                btnhoverSound.play();
                yesBtn.setTexture('yesBtnHover');
            });

            yesBtn.on("pointerout", function () {
                yesBtn.setTexture('yesBtn');
            });

            var noBtn = this.add.sprite(this.game.config.width / 2 + 100, this.game.config.height / 2 + 150, 'noBtn').setInteractive();
            noBtn.setOrigin(0.5);

            noBtn.on("pointerdown", function () {
                btnclickSound.play();
                this.scene.scene.stop('Warning');
                this.scene.scene.resume('Game');
            });

            noBtn.on("pointerover", function () {
                btnhoverSound.play();
                noBtn.setTexture('noBtnHover');
            });

            noBtn.on("pointerout", function () {
                noBtn.setTexture('noBtn');
            });

            var paragraph = this.add.text(this.game.config.width / 2, this.game.config.height / 2, "Your progress in this activity will not be saved.\n\nAre you sure you want to exit?", {
                fontFamily: "Kids Rock DEMO",
                fontSize: 26,
                color: '#1f1c19',
                stroke: '#ffffff',
                align: 'center',
                strokeThickness: 2
            });
            paragraph.setOrigin(0.5, 0.5);
        }
    }

    class Win extends Phaser.Scene {
        constructor() {
            super({ key: "Win" });
        }

        preload() {
        }

        create() {
            window.angularComponentRef.gameFinish();
            this.buttonClick = this.sound.add('buttonclick');
            this.buttonHover = this.sound.add('buttonhover');

            var winSound = this.sound.add("win");// document.getElementById("instAudio");
            winSound.play();

            //var board = this.add.sprite(this.game.config.width/2, this.game.config.height/2, 'board');
            //board.setOrigin(0.5, 0.5);

            var greyRect = this.add.sprite(this.game.config.width / 2 + 6.5, this.game.config.height / 2 + 3.5, 'greyRect');
            greyRect.setOrigin(0.5, 0.523);

            var board = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2, 'frame');
            board.setOrigin(0.5, 0.5)

            var instRect = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 - 30, 'instRect');
            instRect.setOrigin(0.5, 0.53);

            var certificate = this.add.sprite(this.game.config.width / 2 - 180, this.game.config.height / 2 + 30, 'certificate');
            certificate.setOrigin(0.5, 0.5);

            var userName = this.add.text(this.game.config.width / 2 - 180, this.game.config.height / 2 + 17, json.displayName, {
                fontFamily: "PointSoft",
                fontSize: 16,
                color: '#000000',
                align: 'center',
            });
            userName.setOrigin(0.5, 0.5);

            var continents = ["Asia and Oceania", "Europe", "Africa", "South America"];

            var desc = this.add.text(this.game.config.width / 2 - 180, this.game.config.height / 2 + 44, "for completing " + json.title + " in \n the continent of " + continents[json.continentId.split('-')[0] - 1], {
                fontFamily: "PointSoft",
                fontSize: 9,
                color: '#E51B00',
                align: 'center',
            });
            desc.setOrigin(0.5, 0.5);

            var today = new Date();

            var date = this.add.text(this.game.config.width / 2 - 144, this.game.config.height / 2 + 82, today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear(), {
                fontFamily: "PointSoft",
                fontSize: 6,
                color: '#0090d9',
                align: 'center',
            });
            date.setOrigin(0.5, 0.5);

            var title = this.add.text(this.game.config.width / 2, this.game.config.height / 2 - 200, `${json.continent} - ${json.title}`, { fontFamily: 'Kids Rock DEMO', fontSize: 32, color: '#3280db', align: 'center', strokeThickness: 3 });
            title.setOrigin(0.5, 0.9);

            var playBtn = this.add.sprite(this.game.config.width / 2 + 40, this.game.config.height / 2 + 225, 'playagainBtn').setInteractive();
            playBtn.setOrigin(0.5, 0.5);

            var nextBtn = this.add.sprite(this.game.config.width / 2 + 190, this.game.config.height / 2 + 225, 'nextBtn').setInteractive();
            nextBtn.setOrigin(0.5, 0.5);

            var tween = this.tweens.add({
                targets: playBtn,
                scale: 0.8,
                duration: 100,
                ease: 'Power2',
                yoyo: true,
                paused: true
            });

            var tween1 = this.tweens.add({
                targets: playBtn,
                scale: 1.1,
                duration: 100,
                ease: 'Power2',
                paused: true,
            });

            playBtn.on('pointerdown', function () {
                winSound.stop();

                this.scene.scene.start("Welcome");
                this.scene.buttonClick.play();
            });

            playBtn.on('pointerover', function () {
                playBtn.setTexture("playagainBtnHover");
                this.scene.buttonHover.play();
            });

            playBtn.on('pointerout', function () {
                playBtn.setTexture("playagainBtn");
            });

            var canClick = true;

            nextBtn.on('pointerdown', function () {
                winSound.stop();
                if (canClick) {
                    canClick = false;
                    window.angularComponentRef.nextActivity();
                }
            });

            nextBtn.on('pointerover', function () {
                nextBtn.setTexture("nextBtnHover");
                this.scene.buttonHover.play();
            });

            nextBtn.on('pointerout', function () {
                nextBtn.setTexture("nextBtn");
            });

            var congratsTitle = this.add.sprite(this.game.config.width / 2 + 110, this.game.config.height / 2 - 80, 'congratsTitle');
            congratsTitle.setOrigin(0.5, 0.5);

            var content = [
                "You have earned the " + json.title,
                "certificate."

            ];

            var paragraph = this.add.text(this.game.config.width / 2 + 130, this.game.config.height / 2, content, {
                fontFamily: "Kids Rock DEMO",
                fontSize: 23,
                color: '#312f2f',
                stroke: '#312f2f',
                align: 'left'
            });
            paragraph.setOrigin(0.5, 0.5);
            paragraph.setLineSpacing(10);

            var paragraph1 = this.add.text(this.game.config.width / 2 + 130, this.game.config.height / 2 + 110, "You have answered " + gameData.correctQ + " out \nof " + gameData.totalQ + " questions correctly.", {
                fontFamily: "Kids Rock DEMO",
                fontSize: 23,
                color: '#312f2f',
                stroke: '#312f2f',
                align: 'left'
            });
            paragraph1.setOrigin(0.5, 0.5);
            paragraph1.setLineSpacing(10);
        }

        update(time, delta) {
        }
    }

    var config = {
        type: Phaser.CANVAS,
        transparent: true,
        scale: {
            mode: Phaser.Scale.NONE,
            parent: 'game-holder',
            resolution: 1,
        },
        autoCenter: Phaser.Scale.CENTER_BOTH,

        physics: {
            default: 'arcade',
            arcade: {
                gravity: { y: 200 }
            }
        },
        dom: {
            createContainer: false
        },
        render: {
            imageSmoothingEnabled: false,
            transparent: true,
        },
        scene: [Boot, Welcome, Game, Loose, Win, Warning]
    };

    config.json = json
    if (typeof window.orientation !== 'undefined') {
        config.scale.width = 1000;
        config.scale.height = 620;
        config.autoCenter = Phaser.Scale.CENTER_BOTH;
        config.scale.resolution = 1;
    } else {
        config.scale.mode = Phaser.Scale.NONE;
        config.scale.width = 1000;
        config.scale.height = 620;
        config.autoCenter = Phaser.Scale.CENTER_BOTH;
        config.scale.resolution = 1;
    }

    game = new Phaser.Game(config);

    // console.log(game);
    // resize();
    // window.addEventListener("resize", resize, false);

    // console.log(game);

    function resize() {
        var canvas = document.querySelector("canvas");
        var windowWidth = window.innerWidth;
        var windowHeight = window.innerHeight;
        var windowRatio = windowWidth / windowHeight;
        var gameRatio = 1000 / 620;

        console.log("window ratio", windowRatio);
        console.log("game ratio", gameRatio);

        if (windowRatio < gameRatio) {
            canvas.style.width = windowWidth + "px";
            canvas.style.height = (windowWidth / gameRatio) + "px";
        }
        else {
            canvas.style.width = 1000;//(windowHeight * gameRatio) + "px";
            canvas.style.height = 620;//windowHeight + "px";
        }
    }
};

function destoryQuiz() {
    if (game) {
        game.destroy();
    }
}
