var garage;var garage;

var game;
var letterCloud = (json) => {
    (function (garage) {
        class App extends Phaser.Game {
            constructor(json) {
                App.json = json
                let config = {
                    type: Phaser.AUTO,
                    width: App.CANVAS_WIDTH,
                    height: App.CANVAS_HEIGHT,
                    parent: 'game-container',
                    dom: {
                        createContainer: false
                    },
                    scale: {
                        mode: FitScaleManager.detectBestScaleMode(),
                        autoCenter: Phaser.Scale.CENTER_BOTH
                    },
                    transparent: true,
                    scene: {
                        create: () => {
                            this.scene.add('Boot', garage.scene.Boot, true);
                        }
                    }
                };
                super(config);
            }
        }

        App.CANVAS_WIDTH = 980;
        App.CANVAS_HEIGHT = 600;
        garage.App = App;
    })(garage || (garage = {}));

    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    var delayedCalls = [];

    function delayedCall(delay, callback, args, callbackScope) {
        let scene = game.scene.getAt(0);
        if (scene) {
            let dc = scene.time.delayedCall(delay, callback, args, callbackScope);
            delayedCalls.push(dc);
            return dc;
        }
        let t = setTimeout(callback, delay);
        delayedCalls.push(t);
        return t;
    }

    function pauseAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = true;
            }
        }
    }

    function resumeAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = false;
            }
        }
    }

    function destroyAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.remove(false);
            } else {
                clearTimeout(dc);
            }
        }
        delayedCalls = [];
    }

    function destroyDelayedCall(dc) {
        if (dc instanceof Phaser.Time.TimerEvent) {
            dc.remove(false);
        } else {
            clearTimeout(dc);
        }
        let ind = delayedCalls.indexOf(dc);
        if (ind >= 0)
            delayedCalls.splice(ind, 1);
    }

    function setPageBackground(bg) {
        document.querySelector("html").style.backgroundImage = "url(assets/games/letter-cloud/imgs/" + bg + ".jpg)";
    }

    function setupButton(btn, frame) {
        btn.on('pointerdown', () => {
            btn.setFrame(frame + '_hover' + '0000');
        });
        btn.on('pointerover', () => {
            btn.setFrame(frame + '_hover' + '0000');
            game.scene.getAt(0).sound.add("button hover").play();
        });
        btn.on('pointerout', () => {
            btn.setFrame(frame + '0000');
        });
        btn.on('pointerup', () => {
            btn.setFrame(frame + '0000');
            game.scene.getAt(0).sound.add('activity selection - button selection').play();
        });
    }

    function setupButtonTextureBased(btn, texture, hoverTexture) {
        btn.on('pointerdown', () => {
            btn.setTexture(hoverTexture);
        });
        btn.on('pointerover', () => {
            btn.setTexture(hoverTexture);
            game.scene.getAt(0).sound.add("button hover").play();
        });
        btn.on('pointerout', () => {
            btn.setTexture(texture);
        });
        btn.on('pointerup', () => {
            btn.setTexture(texture);
            game.scene.getAt(0).sound.add('activity selection - button selection').play();
        });
    }

    function playBtnClickAnim(target) {
        let sc = target.hasOwnProperty("defScale") ? target["defScale"] : 1;
        target.scaleX = target.scaleY = sc;
        let scene = game.scene.getAt(0);
        scene.tweens.add({
            targets: target,
            "scaleX": 0.9 * sc,
            "scaleY": 0.9 * sc,
            duration: 100,
            yoyo: true
        });
    }

    /**
     * @author Roman Parada
     * This class is created to fix overlapping of bottom part of canvas by navigation bar in iOS.
     * It make a delayed resize of the canvas (like Phaser-3 FIT methods does) and it overrides Phaser-3 input window to Phaser-3 core transform methods
     *
     * How to use:
     * Just call the code line below in Boot scene's init() method of your project:
     * new FitScaleManager(this.game).setup();
     */
    class FitScaleManager {
        constructor(game) {
            this.doResize = () => {
                let scale = this.calculateScale();
                let newCanvasWidth = this.phaserScaleManager.width * scale;
                let newCanvasHeight = this.phaserScaleManager.height * scale;
                this.canvasStyle.width = newCanvasWidth + 'px';
                this.canvasStyle.height = newCanvasHeight + 'px';
                this.canvasStyle.marginLeft = (window.innerWidth - newCanvasWidth) / 2 + 'px';
                this.canvasStyle.marginTop = (window.innerHeight - newCanvasHeight) / 2 + 'px';
            };
            this.game = game;
            this.canvasStyle = this.game.canvas.style;
            this.phaserScaleManager = this.game.scale;
        }

        static detectBestScaleMode() {
            let iOS = /iPad|iPhone|iPod/.test(navigator.platform || "");
            let isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
            return iOS || isAndroid ? Phaser.Scale.FIT : Phaser.Scale.NONE;
        }
        ;

        /**
         * Just call this method once in Boot scene's init() method
         */
        setup() {
            this.phaserScaleManager.addListener(Phaser.Scale.Events.RESIZE, this.onResize, this);
            this.overridePhaserTransformMethods();
            this.onResize();
        }

        calculateScale() {
            if (game.scale.scaleMode == Phaser.Scale.NONE)
                return 1;
            return Math.min(window.innerWidth / this.phaserScaleManager.width, window.innerHeight / this.phaserScaleManager.height);
        }

        overridePhaserTransformMethods() {
            this.game.scale.transformX = (pageX) => {
                return (pageX - parseInt(this.canvasStyle.marginLeft.split("px")[0])) / this.calculateScale();
            };
            this.game.scale.transformY = (pageY) => {
                return (pageY - parseInt(this.canvasStyle.marginTop.split("px")[0])) / this.calculateScale();
            };
        }

        onResize() {
            setTimeout(this.doResize, FitScaleManager.RESIZE_DELAY);
        }
    }

    FitScaleManager.RESIZE_DELAY = 500;

    (function (garage) {
        var core;
        (function (core) {
            class Gameplay {
                constructor() {
                    this.allLettersNames = null;
                    this.currentRound = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                    this.onNewRound = null;
                    this.nextLetterDelay = 0;
                    this.roundsVaryFrom = Number(game.cache.json.get('gameplay')["roundsVaryFrom"]);
                    this.roundsVaryTo = Number(game.cache.json.get('gameplay')["roundsVaryTo"]);
                    this.useImages = Boolean(game.cache.json.get('gameplay')["useImages"]);
                    this.wordsLetters = game.cache.json.get('gameplay')["wordsLetters"];
                }
                getRoundWordCharAt(at) {
                    return this.wordsLetters[this.roundWord][at];
                }

                getRoundWordLettersNumber() {
                    return this.wordsLetters[this.roundWord].length;
                }

                setupCallbacks(onComplete, onLose, onNewRound) {
                    this.onComplete = onComplete;
                    this.onNewRound = onNewRound;
                }

                calculateScore() {
                    return this.totalRoundsNum; //3 * this.totalRoundsNum - this.wrongAnswersCount;
                }

                onLetterChosen() {
                    if (this.correctAnswersCountThisRound == 1) {
                        this.currentRound++;
                        if (this.currentRound >= this.totalRoundsNum) {
                            let score = this.calculateScore();
                            this.onComplete(score, score);
                            return true;
                        } else {
                            this.nextWord();
                        }
                    }
                    return false;
                }

                nextWord() {
                    let fn = () => {
                        let round = this.rounds[this.currentRound];
                        this.roundWord = round["correctWord"];
                        this.roundWords = Phaser.Utils.Array.Shuffle([this.roundWord, round["wrongWord1"], round["wrongWord2"]]);
                        this.correctAnswersCountThisRound = 0;
                        this.wrongAnswersCountThisRound = 0;
                        if (this.onNewRound)
                            this.onNewRound();
                    };
                    if (this.nextLetterDelay == 0) {
                        fn();
                    } else {
                        delayedCall(this.nextLetterDelay, fn);
                    }
                }

                onCorrectAnswer() {
                    this.correctAnswersCount++;
                    this.correctAnswersCountThisRound++;
                    this.nextLetterDelay = 2500;
                    return this.onLetterChosen();
                }

                onWrongAnswer() {
                    this.wrongAnswersCount++;
                    this.wrongAnswersCountThisRound++;
                    this.nextLetterDelay = 0;
                    this.onLetterChosen();
                    return false;
                }

                getCurrentTotalAnswersCount() {
                    return this.correctAnswersCount + this.wrongAnswersCount;
                }

                getCurrentTotalAnswersCountThisRound() {
                    return this.correctAnswersCountThisRound + this.wrongAnswersCountThisRound;
                }

                isNewRound() {
                    return this.getCurrentTotalAnswersCountThisRound() == 0;
                }

                isRoundsComplete() {
                    return this.currentRound >= this.totalRoundsNum;
                }

                getAudioKeyOfChar(letter) {
                    let json = game.cache.json.get('gameplay');
                    let letters = json["letters"];
                    for (let d of letters) {
                        if (letter == d['letterName']) {
                            return d['audioKey'];
                        }
                    }
                    return null;
                }

                reset() {
                    this.currentRound = 0;
                    this.totalRoundsNum = Math.round(Math.random() * (this.roundsVaryTo - this.roundsVaryFrom)) + this.roundsVaryFrom;
                    this.nextLetterDelay = 0;
                    this.setupCallbacks(null, null, null);
                    let json = game.cache.json.get('gameplay');
                    this.letters = json["letters"].slice();
                    this.allLettersNames = [];
                    for (let d of this.letters)
                        this.allLettersNames.push(d["letterName"]);
                    this.rounds = JSON.parse(JSON.stringify(json["rounds"]));
                    this.totalRoundsNum = this.rounds.length;
                    this.nextWord();
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                }
            }

            core.Gameplay = Gameplay;
        })(core = garage.core || (garage.core = {}));
    })(garage || (garage = {}));

    (function (garage) {
        var scene;
        (function (scene) {
            class Boot extends Phaser.Scene {
                init() {
                    // new FitScaleManager(this.game).setup();
                    this.game.scale.transformX = (pageX) => {
                        let offsetLeft = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetLeft) {
                                offsetLeft = parentElement.offsetLeft;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageX - offsetLeft) * this.game.scale.displayScale.x;
                    };
                    this.game.scale.transformY = (pageY) => {
                        let offsetTop = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetTop) {
                                offsetTop = parentElement.offsetTop;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageY - offsetTop) * this.game.scale.displayScale.y;
                    };
                }

                create() {
                    game.scene.remove('Boot');
                    game.scene.add('Preloader', garage.scene.Preloader, true);
                }
            }

            scene.Boot = Boot;
        })(scene = garage.scene || (garage.scene = {}));
    })(garage || (garage = {}));

    (function (garage) {
        var scene;
        (function (scene) {
            var Gameplay = garage.core.Gameplay;

            class MainScene extends Phaser.Scene {
                create() {
                    this.gameplay = new Gameplay();
                    this.gameplayScreen = new garage.screen.GameplayScreen(this, this.gameplay);
                    this.children.add(this.gameplayScreen);
                    this.gameplayScreen.showInstructionPage();
                }
            }

            scene.MainScene = MainScene;
        })(scene = garage.scene || (garage.scene = {}));
    })(garage || (garage = {}));

    (function (garage) {
        var scene;
        (function (scene) {
            class Preloader extends Phaser.Scene {
                preload() {
                    if (typeof garage.App.json == "string") this.load.json('gameplay', garage.App.json); else this.game.cache.json.add('gameplay', garage.App.json);
                }

                create() {
                    let json = game.cache.json.get('gameplay');
                    let words = json["words"];
                    if (json["useImages"]) {
                        let rounds = JSON.parse(JSON.stringify(json["rounds"]));
                        for (let i = 0; i < rounds.length; i++) {
                            let w = rounds[i];
                            this.load.image(w.correctWord, "assets/games/letter-cloud/imgs/letters/" + w.correctWord + ".png");
                            this.load.image(w.wrongWord1, "assets/games/letter-cloud/imgs/letters/" + w.wrongWord1 + ".png");
                            this.load.image(w.wrongWord2, "assets/games/letter-cloud/imgs/letters/" + w.wrongWord2 + ".png");
                        }
                        for (let l of json["letters"]) {
                            this.load.image(l["letterName"], "assets/games/letter-cloud/imgs/letters/" + l["letterName"] + ".png");
                        }
                    }
                    for (let l of json["letters"]) {
                        this.load.audio(l["audioKey"], "assets/games/letter-cloud/sound/mp3/letters/" + l["audioKey"] + ".mp3");
                    }
                    for (let w of words) {
                        this.load.audio(w, "assets/games/letter-cloud/sound/mp3/words/" + w + ".mp3");
                        this.load.audio('tapon_' + w, "assets/games/letter-cloud/sound/mp3/words/tapon/" + w + ".mp3");
                    }
                    let progressTxt = this.add.text(game.scale.width / 2, game.scale.height / 2, "", {
                        "fontFamily": "QuranErafont",
                        "fontSize": 25,
                        "color": "#000000",
                        "align": 'center'
                    });
                    progressTxt.setOrigin(0.5, 0.5);
                    this.load.pack('preloader', 'assets/games/letter-cloud/pack.json');
                    this.load.on('progress', (value) => {
                        progressTxt.text = Math.ceil(value * 100) + "%";
                    }, this);
                    this.load.on('complete', () => {
                        this.nextScene();
                    });
                    this.load.start();
                }

                static playAnim(animKey, sprite, onComplete = null) {
                    let mainScene = game.scene.getScene('ScreenMain');
                    let data = Preloader.ANIMS_DATA[animKey];
                    if (!mainScene.anims.exists(animKey)) {
                        mainScene.anims.create({
                            key: animKey,
                            frames: mainScene.anims.generateFrameNames(data['atlas'], {
                                start: data['start'], end: data['end'], zeroPad: data['padNum'],
                                prefix: data['prefix'], suffix: ''
                            }),
                            frameRate: data['frameRate'],
                            repeat: data['repeat']
                        });
                    }
                    if (sprite.anims.currentAnim) {
                        sprite.anims.currentAnim.off('complete');
                    }
                    sprite.anims.stop();
                    sprite.play(animKey);
                    sprite.anims.currentAnim.once('complete', () => {
                        if (onComplete)
                            onComplete();
                    });
                    sprite.setOrigin(data['originX'], data['originY']);
                    return sprite;
                }

                nextScene() {
                    game.scene.remove('Preloader');
                    game.scene.add('ScreenMain', garage.scene.MainScene, true);
                }
            }

            Preloader.ANIMS_DATA = {
                'Birds_Iddle': {
                    'start': 0,
                    'end': 85,
                    'padNum': 4,
                    'prefix': 'Birds_Iddle',
                    'repeat': 0,
                    'frameRate': 24,
                    'atlas': 'Birds Iddle v2',
                    'originX': 0.5,
                    'originY': 0.5
                },
                'Birds_Great': {
                    'start': 0,
                    'end': 47,
                    'padNum': 4,
                    'prefix': 'Birds_Great',
                    'repeat': 0,
                    'frameRate': 24,
                    'atlas': 'Birds Great v2',
                    'originX': 0.44,
                    'originY': 0.58
                }
            };
            scene.Preloader = Preloader;
        })(scene = garage.scene || (garage.scene = {}));
    })(garage || (garage = {}));

    (function (garage) {
        var screen;
        (function (screen) {
            class AreYouSureWindow extends Phaser.GameObjects.Container {
                constructor(scene, onYes, onNo) {
                    super(scene);
                    this._areYouSurePage = new Phaser.GameObjects.Image(this.scene, -105, 0 - 48, 'Exit warning');
                    this._areYouSurePage.setOrigin(0, 0);
                    this._areYouSurePage.setInteractive();
                    var modalTextStyle = {
                        backgroundColor: 'transparent',
                        stroke: '#ffffff',
                        strokeThickness: 2,
                        lineSpacing: 15,
                        fontFamily: 'Kids Rock DEMO',
                        fontSize: 27,
                        color: '#43425d',
                        wordWrap: true,
                        align: 'center'
                    };
                    var modalText = this.scene.add.text(game.scale.width / 2 + 12, game.scale.height / 2, "Your progress in this activity will not be saved. \n Are you sure you want to exit? ", modalTextStyle);
                    modalText.setOrigin(0.5, 0.5);
                    this._btnSureYes = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 - 95, 485 - 50, 'btnYES1');
                    this._btnSureYes.setInteractive({ cursor: 'pointer' });
                    this._btnSureYes.once('pointerup', () => {
                        onYes;
                        window.angularComponentRef.backToMenu();
                    });
                    setupButtonTextureBased(this._btnSureYes, 'btnYES1', 'btnYES2');
                    this._btnSureNo = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 + 95, 485 - 50, 'btnNO1');
                    this._btnSureNo.setInteractive({ cursor: 'pointer' });
                    this._btnSureNo.once('pointerup', onNo);
                    setupButtonTextureBased(this._btnSureNo, 'btnNO1', 'btnNO2');
                    this.add(this._areYouSurePage);
                    this.add(this._btnSureYes);
                    this.add(this._btnSureNo);
                    this.add(modalText);
                }
            }

            screen.AreYouSureWindow = AreYouSureWindow;
        })(screen = garage.screen || (garage.screen = {}));
    })(garage || (garage = {}));

    (function (garage) {
        var screen;
        (function (screen) {
            class CompleteWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay, onNext) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-104.5, -48);
                    this._bgComplete = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Completion page LATEST UPDATED');
                    this._bgComplete.setOrigin(0, 0);
                    this._bgComplete.setInteractive();
                    this._cup = new Phaser.GameObjects.Image(this.scene, 400, 410, 'Trophy');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 570, 570, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 720, 570, 'btnReplay1');
                    this._btnNext = new Phaser.GameObjects.Image(this.scene, 870, 570, 'btnNEXT1');
                    let _CollectedPoints = new Phaser.GameObjects.Image(this.scene, 620, 440, 'Collected Points');
                    this.totalScoreTxt = this.scene.add.text(845, 352, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(648, 433, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bgComplete,
                        _CollectedPoints,
                        this._cup,
                        this._btnBack,
                        this._btnReplay,
                        this._btnNext,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        window.angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                    this._btnNext.setInteractive({ cursor: 'pointer' });
                    this._btnNext.on('pointerup', () => {
                        onNext(this._btnNext);
                        window.angularComponentRef.nextActivity();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnNext, 'btnNEXT1', 'btnNEXT2');
                }

                show(score, starScore) {
                    window.angularComponentRef.gameFinish({ score, starScore })
                    this._cup.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._cup,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(starScore);
                    // let music = this.scene.sound.add("viktory");
                    this.music = this.scene.sound.add("Activity completion fantastic");
                    this.music.play();
                }
            }

            screen.CompleteWindow = CompleteWindow;
        })(screen = garage.screen || (garage.screen = {}));
    })(garage || (garage = {}));

    (function (garage) {
        var screen;
        (function (screen) {
            var Preloader = garage.scene.Preloader;

            class GameplayScreen extends Phaser.GameObjects.Container {
                constructor(scene, gameplay) {
                    super(scene);
                    this.bgMusic = null;
                    this.playIdle = () => {
                        Preloader.playAnim('Birds_Iddle', this.character, this.playIdle);
                    };
                    this.wfsnd = null;
                    this.showCompleteWindow = (score, starScore) => {
                        let completeWindow = new screen.CompleteWindow(this.scene, (target) => {
                            playBtnClickAnim(target);
                        }, (target) => {
                            playBtnClickAnim(target);
                            this.destroyGameplay();
                            this.remove(completeWindow);
                            this.showInstructionPage();
                        }, (target) => {
                            playBtnClickAnim(target);
                        });
                        this.setInputEnabled(false);
                        delayedCall(2000, () => {
                            setPageBackground("bg-blue");
                            this.add(completeWindow);
                            completeWindow.show(score, starScore);
                            this.bgMusic.stop();
                        });
                    };
                    this.showLoseWindow = (score, starScore) => {
                        let tryAgainWindow = new screen.TryAgainWindow(this.scene, (target) => {
                            playBtnClickAnim(target);
                        }, (target) => {
                            playBtnClickAnim(target);
                            this.destroyGameplay();
                            this.remove(tryAgainWindow);
                            this.showInstructionPage();
                        });
                        this.setInputEnabled(false);
                        delayedCall(2500, () => {
                            setPageBackground("bg-blue");
                            this.add(tryAgainWindow);
                            tryAgainWindow.show(score, starScore);
                            this.bgMusic.stop();
                        });
                    };
                    this.gameplay = gameplay;
                    window["gs"] = this;
                    this._gameStage = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, game.scale.height / 2, 'Page 1');
                    this._gameStage.setOrigin(0.5, 0.52);
                    this._gameStage.setInteractive();
                    this.add(this._gameStage);
                    this._btnSound = new Phaser.GameObjects.Image(this.scene, 160 - 105, 100 - 50, 'Sound');
                    this._btnSound.setInteractive({ cursor: 'pointer' });
                    setupButtonTextureBased(this._btnSound, 'Sound', 'Sound HOVER EFFECT');
                    this.add(this._btnSound);
                    this._btnSound.on('pointerup', () => {
                        playBtnClickAnim(this._btnSound);
                        this.scene.sound.add("tapon_" + this.gameplay.roundWord).play();
                    });
                    this._btnSound.visible = false;
                    this.character = this.scene.add.sprite(0, 0, null);
                    this.character.setPosition(170, 490);
                    this.add(this.character);
                    this.playIdle();
                    this._btnClose = new Phaser.GameObjects.Image(this.scene, 1025 - 105, 100 - 50, 'x Button');
                    this._btnClose.setInteractive({ cursor: 'pointer' });
                    this._btnClose["defScale"] = this._btnClose.scale;
                    setupButtonTextureBased(this._btnClose, 'x Button', 'x Button HOVER EFFECT');
                    this.add(this._btnClose);
                    this._btnClose.on('pointerup', () => {
                        playBtnClickAnim(this._btnClose);
                        this.onCloseClick();
                    });
                }

                showGameplay() {
                    setPageBackground("bg-australia");
                    this.gameplay.reset();
                    this.bgMusic = this.scene.sound.add("Bachground ambience");
                    this.bgMusic.play();
                    this.bgMusic.loop = true;
                    this.gameplayContainer = new Phaser.GameObjects.Container(this.scene);
                    this.addAt(this.gameplayContainer, this.getIndex(this.character));
                    this.prepareRound();
                    this.gameplay.setupCallbacks(this.showCompleteWindow, this.showLoseWindow, () => {
                        this.onNewRound(true);
                    });
                }

                prepareRound() {
                    this._btnSound.visible = false;
                    this.gameplayContainer.removeAll();
                    this.clouds = [];
                    for (let i = 0; i < 3; i++) {
                        let c = new Phaser.GameObjects.Container(this.scene, 200 + i * 290, 240);
                        this.gameplayContainer.add(c);
                        this.clouds.push(c);
                        c['defX'] = c.x;
                        c['defY'] = c.y;
                        let cSprite = new Phaser.GameObjects.Image(this.scene, 0, 0, 'cloud ' + (i + 1));
                        c.add(cSprite);
                        c["-image-"] = cSprite;
                        let charAt = {};
                        let roundWordLettersNumber = {};
                        if (json.wordsLetters == undefined) {
                            charAt = this.gameplay.roundWord.charAt(this.gameplay.roundWord.length - 1 - i);
                        }
                        else {
                            roundWordLettersNumber = this.gameplay.getRoundWordLettersNumber();
                            charAt = this.gameplay.getRoundWordCharAt(roundWordLettersNumber - 1 - i);
                        }
                        let txt;
                        if (this.gameplay.useImages) {
                            txt = new Phaser.GameObjects.Image(this.scene, 0, 0, charAt);
                        } else {
                            txt = this.scene.add.text(0, 0, "", {
                                "fontFamily": "QuranErafont",
                                "fontSize": 85,
                                "color": "#000000",
                                "align": 'center'
                            });
                            txt.setOrigin(0.5, 0.5);
                            txt.style.fixedHeight = 120;
                            txt.setText(charAt);
                        }
                        c.add(txt);
                        c["-txt-"] = txt;
                        if (json.wordsLetters == undefined) {
                            c["-txt-text"] = this.gameplay.roundWord.charAt(this.gameplay.roundWord.length - 1 - i);
                        }
                        else {
                            c["-txt-text"] = charAt;
                        }
                        c['handIcon'] = new Phaser.GameObjects.Image(this.scene, 0, -140, 'pointer arrow 1');
                        c.add(c['handIcon']);
                        c['handIcon'].visible = false;
                        this.scene.tweens.add({
                            targets: c['handIcon'],
                            "y": c['handIcon'].y - 25,
                            duration: 750,
                            yoyo: true,
                            repeat: -1
                        });
                        c.alpha = 0;
                        this.scene.tweens.add({
                            targets: c,
                            "alpha": 1,
                            duration: 250,
                            delay: 1000 + (2 - i) * 150
                        });
                    }
                    delayedCall(750, () => {
                        this.scene.sound.add("Letter clouds appear in each round-question").play();
                    });
                    this.preparePartA();
                    this.createInput();
                    this.setInputEnabled(false);
                    // delayedCall(1200, ()=>{
                    if (this.gameplay.isNewRound()) {
                        if (!this.gameplay.isRoundsComplete()) {
                            // delayedCall(2000, ()=>{
                            this.setInputEnabled(true);
                            // });
                        }
                    } else {
                        this.setInputEnabled(true);
                    }
                    // });
                }

                preparePartA() {
                    for (let i = 0; i < this.clouds.length; i++) {
                        let c = this.clouds[i];
                        c["-image-"].setInteractive({ cursor: 'pointer', pixelPerfect: true });
                        c["-image-"].on('pointerdown', () => {
                            if (!c['handIcon'].visible)
                                return;
                            c["-image-"].off('pointerdown');
                            c['handIcon'].visible = false;
                            this.scene.sound.add(this.gameplay.getAudioKeyOfChar(c["-txt-text"])).play();
                            this.scene.tweens.add({
                                targets: c,
                                "scale": 0.9,
                                duration: 250,
                                yoyo: true
                            });
                            if (i == 0) {
                                delayedCall(1000, () => {
                                    this.preparePartB();
                                });
                            } else {
                                delayedCall(1000, () => {
                                    this.clouds[i - 1]['handIcon'].visible = true;
                                });
                            }
                        });
                    }
                    this.clouds[this.clouds.length - 1]['handIcon'].visible = true;
                }

                preparePartB() {
                    this.scene.sound.add('cloudsjoinsfx').play();
                    let pos = { x: game.scale.width / 2, y: this.clouds[1].y };
                    for (let c of this.clouds) {
                        this.scene.tweens.add({
                            targets: c,
                            x: pos.x,
                            y: pos.y,
                            alpha: 0,
                            duration: 250
                        });
                    }
                    let cloudsContainer = new Phaser.GameObjects.Container(this.scene, pos.x, pos.y);
                    let clouds = new Phaser.GameObjects.Image(this.scene, 0, 0, 'clouds');
                    cloudsContainer.add(clouds);
                    let txt;
                    if (this.gameplay.useImages) {
                        txt = new Phaser.GameObjects.Image(this.scene, 0, 0, this.gameplay.roundWord);
                    } else {
                        txt = this.scene.add.text(0, 0, "", {
                            "fontFamily": "QuranErafont",
                            "fontSize": 85,
                            "color": "#000000",
                            "align": 'center'
                        });
                        txt.setOrigin(0.5, 0.5);
                        txt.style.fixedHeight = 120;
                        txt.setText(this.gameplay.roundWord);
                    }
                    txt['-txt-text'] = this.gameplay.roundWord;
                    cloudsContainer.add(txt);
                    this.gameplayContainer.add(cloudsContainer);
                    clouds.alpha = 0;
                    this.scene.tweens.add({
                        targets: clouds,
                        alpha: 1,
                        duration: 750
                    });
                    delayedCall(1500, () => {
                        this.scene.sound.add(this.gameplay.roundWord).play();
                    });
                    delayedCall(3000, () => {
                        delayedCall(1000, () => {
                            this.scene.tweens.add({
                                targets: cloudsContainer,
                                alpha: 0,
                                duration: 500,
                                onComplete: () => {
                                    this.gameplayContainer.remove(cloudsContainer);
                                }
                            });
                            for (let i = 0; i < this.clouds.length; i++) {
                                let c = this.clouds[i];
                                c["-image-"].setTexture('clouds');
                                c["-image-"].setScale(0.75);
                                c["-image-"].setOrigin(0.5, 0.5);
                                this.scene.tweens.add({
                                    targets: c,
                                    x: c['defX'],
                                    y: c['defY'],
                                    alpha: 1,
                                    duration: 500
                                });
                                this.scene.sound.add("cloud words appear").play();
                                if (txt instanceof Phaser.GameObjects.Text) {
                                    c['-txt-'].setFontSize(65);
                                    txt.style.fixedHeight = 65;
                                    c['-txt-'].setText(this.gameplay.roundWords[i]);
                                } else {
                                    c['-txt-'].setTexture(this.gameplay.roundWords[i]);
                                    c['-txt-'].setScale(0.75)
                                }
                                c['-txt-text'] = this.gameplay.roundWords[i];
                            }
                            this.preparePartC();
                        });
                    });
                }

                preparePartC() {
                    this._btnSound.visible = true;
                    delayedCall(750, () => this.scene.sound.add('tapon_' + this.gameplay.roundWord).play());
                    for (let c of this.clouds) {
                        c["-image-"].on('pointerdown', () => {
                            if (c['-txt-text'] == this.gameplay.roundWord) {
                                this.setInputEnabled(false);
                                for (let _c of this.clouds) {
                                    this.scene.tweens.add({
                                        targets: _c,
                                        "alpha": 0,
                                        duration: 1000,
                                        delay: 1000
                                    });
                                }
                                this.onCorrectAnswer();
                                c["-image-"].setScale(1);
                                c["-image-"].setTexture("clouds_correct");
                            } else {
                                this.onWrongAnswer();
                                c["-image-"].setTexture("clouds_incorrect");
                                c["-image-"].setScale(1);
                                delayedCall(1000, () => {
                                    c["-image-"].setTexture("clouds");
                                    c["-image-"].setScale(0.75);
                                });
                            }
                            this.scene.tweens.add({
                                targets: c,
                                "scale": 0.9,
                                duration: 250,
                                yoyo: true
                            });
                        });
                    }
                }

                onNewRound(showOut) {
                    this.setInputEnabled(false);
                    if (showOut) {
                        this.prepareRound();
                    }
                }

                createInput() {
                    this._btnClose.setInteractive({ cursor: 'pointer', pixelPerfect: true });
                }

                onCorrectAnswer() {
                    let completed = this.gameplay.onCorrectAnswer();
                    this.scene.sound.add("correct").play();
                    delayedCall(600, () => {
                        Preloader.playAnim('Birds_Great', this.character, this.playIdle);
                        this.scene.sound.add(this.birdCorrectSndCounter++ % 2 == 0 ? "Bird - yes thats it" : "Bird fantastic").play();
                    });
                    return completed;
                }

                onWrongAnswer() {
                    let lost = this.gameplay.onWrongAnswer();
                    this.scene.sound.add("wrong click").play();
                    return lost;
                }

                onCloseClick() {
                    this.showAreYouSurePage();
                    this.scene.sound.add('warning page pop up sfx').play();
                }

                showInstructionPage() {
                    setPageBackground("bg-blue");
                    let playInstructionSound = () => {
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                        this.wfsnd = this.scene.sound.add("Letter cloud instruction");
                        this.wfsnd.play();
                    };
                    this.instructionPage = new screen.InstructionPage(this.scene, (target) => {
                        playBtnClickAnim(target);
                        this.remove(this.instructionPage);
                        this.showGameplay();
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                    }, (target) => {
                        playBtnClickAnim(target);
                        playInstructionSound();
                    });
                    this.add(this.instructionPage);
                    try {
                        playInstructionSound();
                    }
                    catch (e) {
                        console.error(e);
                    }
                }

                showAreYouSurePage() {
                    pauseAllDelayedCalls();
                    setPageBackground("bg-blue");
                    this.pauseSounds();
                    this.areYouSureWindow = new screen.AreYouSureWindow(this.scene, () => {
                        this.remove(this.areYouSureWindow);
                        this.destroyGameplay();
                        this.showInstructionPage();
                    }, () => {
                        this.remove(this.areYouSureWindow);
                        this.unpauseSounds();
                        resumeAllDelayedCalls();
                        setPageBackground("bg-australia");
                    });
                    this.add(this.areYouSureWindow);
                }

                setInputEnabled(enabled) {
                    if (enabled) {
                        for (let c of this.clouds)
                            c["-image-"].setInteractive({ cursor: 'pointer', pixelPerfect: true });
                    } else {
                        for (let c of this.clouds)
                            c["-image-"].disableInteractive();
                    }
                }

                pauseSounds() {
                    this.scene.sound.pauseAll();
                }

                unpauseSounds() {
                    this.scene.sound.resumeAll();
                }

                destroyGameplay() {
                    this.setInputEnabled(false);
                    this.remove(this.gameplayContainer);
                    this.scene.sound.stopAll();
                    destroyAllDelayedCalls();
                }
            }

            screen.GameplayScreen = GameplayScreen;
        })(screen = garage.screen || (garage.screen = {}));
    })(garage || (garage = {}));

    (function (garage) {
        var screen;
        (function (screen) {
            class InstructionPage extends Phaser.GameObjects.Container {
                constructor(scene, onPlayClick, onSndClick) {
                    super(scene);
                    this._instructionPage = new Phaser.GameObjects.Image(this.scene, 0 - 105, 0 - 48, 'Instructions page  ALL ACTIVITY  TITLEs');
                    this._instructionPage.setOrigin(0, 0);
                    this._instructionPage.setInteractive();
                    this._instructionPageTitle = new Phaser.GameObjects.Image(this.scene, 495, 105, 'welcome');
                    this._instructionPageTitle.setScale(0.7);
                    this._btnPlay = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, 480 - 50, 'btnPLAY1');
                    this._btnPlay.setInteractive({ cursor: 'pointer' });
                    this._btnPlay.once('pointerup', onPlayClick);
                    setupButtonTextureBased(this._btnPlay, 'btnPLAY1', 'btnPLAY2');
                    this.instrTxt = this.scene.add.text(game.scale.width / 2, game.scale.height / 2, "See how letters join to make a word.\nThen tap the cloud with the right word.", {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 30,
                        "color": "#43425D",
                        "align": 'center'
                    });
                    this.instrTxt.setLineSpacing(15);
                    this.instrTxt.setOrigin(0.5, 0.5);
                    this._btnSoundInstruction = new Phaser.GameObjects.Image(this.scene, 800 - 105, 156 - 50, 'Sound');
                    this._btnSoundInstruction.setInteractive({ cursor: 'pointer' });
                    this._btnSoundInstruction.on('pointerup', onSndClick);
                    setupButtonTextureBased(this._btnSoundInstruction, 'Sound', 'Sound HOVER EFFECT');
                    this.add(this._instructionPage);
                    this.add(this._instructionPageTitle);
                    this.add(this.instrTxt);
                    this.add(this._btnPlay);
                    this.add(this._btnSoundInstruction);
                }
            }

            screen.InstructionPage = InstructionPage;
        })(screen = garage.screen || (garage.screen = {}));
    })(garage || (garage = {}));

    (function (garage) {
        var screen;
        (function (screen) {
            class TryAgainWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-106, -48);
                    this._bg = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Try again page');
                    this._bg.setOrigin(0, 0);
                    this._bg.setInteractive();
                    this._star = new Phaser.GameObjects.Image(this.scene, 400, 415, 'Break Star');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 600, 580, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 765, 580, 'btnReplay1');
                    this.totalScoreTxt = this.scene.add.text(830, 355, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(635, 431, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bg,
                        this._star,
                        this._btnBack,
                        this._btnReplay,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                }

                show(score, starScore) {
                    this._star.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._star,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(0);
                    this.music = this.scene.sound.add("Fail - close one");
                    this.music.play();
                }
            }

            screen.TryAgainWindow = TryAgainWindow;
        })(screen = garage.screen || (garage.screen = {}));
    })(garage || (garage = {}));

    game = new garage.App(json);
};

function destroyLetterCloud() {
    if (game) {
        game.destroy();
    }
}
