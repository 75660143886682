var rp;
var game;

var reciteTheVerse = (json) => {
    (function (rp) {
        class App extends Phaser.Game {
            constructor(json) {
                App.json = json;
                let config = {
                    type: Phaser.AUTO,
                    width: App.CANVAS_WIDTH,
                    height: App.CANVAS_HEIGHT,
                    parent: 'game-container',
                    dom: {
                        createContainer: false
                    },
                    scale: {
                        mode: Phaser.Scale.NONE,
                        // autoCenter: Phaser.Scale.CENTER_BOTH
                    },
                    transparent: true,
                    scene: {
                        create: () => {
                            this.scene.add('Boot', rp.scene.Boot, true);
                        }
                    }
                };
                super(config);
            }
        }

        App.CANVAS_WIDTH = 980;
        App.CANVAS_HEIGHT = 600;
        rp.App = App;
    })(rp || (rp = {}));

    let delayedCalls = [];
    function delayedCall(delay, callback, args, callbackScope) {
        let scene = game.scene.getAt(0);
        if (scene) {
            let dc = scene.time.delayedCall(delay, callback, args, callbackScope);
            delayedCalls.push(dc);
            return dc;
        }
        let t = setTimeout(callback, delay);
        delayedCalls.push(t);
        return t;
    }
    function pauseAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = true;
            }
        }
    }
    function resumeAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = false;
            }
        }
    }
    function destroyAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.remove(false);
            }
            else {
                clearTimeout(dc);
            }
        }
        delayedCalls = [];
    }
    function setPageBackground(bg) {
        document.querySelector("html").style.backgroundImage = "url(assets/imgs/" + bg + ".jpg)";
    }
    function setupButton(btn, frame) {
        btn.on('pointerdown', () => {
            btn.setFrame(frame + '_hover' + '0000');
        });
        btn.on('pointerover', () => {
            btn.setFrame(frame + '_hover' + '0000');
            game.scene.getAt(0).sound.add("button hover").play();
        });
        btn.on('pointerout', () => {
            btn.setFrame(frame + '0000');
        });
        btn.on('pointerup', () => {
            btn.setFrame(frame + '0000');
            game.scene.getAt(0).sound.add('activity selection - button selection').play();
        });
    }
    function setupButtonTextureBased(btn, texture, hoverTexture) {
        btn.on('pointerdown', () => {
            btn.setTexture(hoverTexture);
        });
        btn.on('pointerover', () => {
            btn.setTexture(hoverTexture);
            game.scene.getAt(0).sound.add("button hover").play();
        });
        btn.on('pointerout', () => {
            btn.setTexture(texture);
        });
        btn.on('pointerup', () => {
            btn.setTexture(texture);
            game.scene.getAt(0).sound.add('activity selection - button selection').play();
        });
    }
    function setupButtonFrameBased(btn, texture, defaultFrame, hoverFrame, playClickSound = false, playHoverSound = false) {
        btn.setTexture(texture, defaultFrame);
        btn.on('pointerdown', () => {
            btn.setFrame(hoverFrame);
        });
        btn.on('pointerover', () => {
            btn.setFrame(hoverFrame);
            if (playHoverSound)
                game.scene.getAt(0).sound.add("button hover").play();
        });
        btn.on('pointerout', () => {
            btn.setFrame(defaultFrame);
        });
        btn.on('pointerup', () => {
            btn.setFrame(defaultFrame);
            if (playClickSound)
                game.scene.getAt(0).sound.add('click').play();
        });
    }
    class AbstractPage extends Phaser.GameObjects.Container {
        constructor(scene, pageIndex) {
            super(scene);
            this.pageIndex = pageIndex;
            this.contentContainer = new Phaser.GameObjects.Container(this.scene, pageIndex % 2 === 0 ? -456 : 2, 0);
            this.add(this.contentContainer);
            this.pageBackground = new Phaser.GameObjects.Image(this.scene, 0, 0, pageIndex % 2 === 0 ? 'page-bg' : 'page-bg-front', 0);
            this.pageBackground.setOrigin(0, 0.5);
            this.contentContainer.add(this.pageBackground);

            this.pageBackground.setVisible(false);
        }
    }
    class EmptyPage extends AbstractPage {
        constructor(scene, pageIndex) {
            super(scene, pageIndex);
        }
        buildContent() {
        }
    }
    class EndPage extends AbstractPage {
        constructor(scene, pageIndex, onCompleteCallback) {
            super(scene, pageIndex);
            this.pageBackground.alpha = 0;
            this.buttonComplete = new Phaser.GameObjects.Image(this.scene, 220, 50, 'complete-button');
            this.buttonComplete.setInteractive({ cursor: 'pointer' });
            setupButtonFrameBased(this.buttonComplete, 'complete-button', 1, 0, true, true);
            this.buttonComplete.on('pointerup', () => onCompleteCallback());
            this.contentContainer.add([this.buttonComplete]);
        }
        buildContent() {
        }
    }
    class Page extends AbstractPage {
        constructor(scene, pageIndex, dataIndex, displayedNumber) {
            super(scene, pageIndex);
            this.dataIndex = dataIndex;
            this.displayedNumber = displayedNumber;
            this.buildContent();
        }
        buildContent() {
            // this.frameBg = new Phaser.GameObjects.Image(this.scene, 225, 0, 'frame-bg');
            const gameplayConfig = this.scene.game.cache.json.get('gameplay');

            const pages = gameplayConfig.data.pages;
            if (pages[this.dataIndex]["useImages"]) {
                this.text = new Phaser.GameObjects.Image(this.scene, 225, -45, pages[this.dataIndex].text);
            } else {
                this.text = new Phaser.GameObjects.Text(this.scene, 225, -45, "", {
                    "fontFamily": json.data.defaultFontFamily ? json.data.defaultFontFamily : "HafsQuranEra",
                    "fontSize": pages[this.dataIndex].fontSize ? pages[this.dataIndex].fontSize : json.data.defaultFontSize ? json.data.defaultFontSize : 45 * 2,
                    "color": "#010101",
                    "align": 'center'
                });
                this.text.setOrigin(0.5, 0.5);
                this.text.setText(pages[this.dataIndex].text);
                this.text.setPadding(0, 30);
            }
            let limitHeight = 240;
            let scale = limitHeight / this.text.height;
            if (this.text.height > limitHeight) this.text.setScale(scale);
            let limitWidth = 520;
            if (this.text.width > limitWidth) {
                scale = Math.min(scale, limitWidth / this.text.width);
                this.text.setScale(scale);
            }

            this.vocalizeBtn = new Phaser.GameObjects.Image(this.scene, 225, 108, 'reading-sound', 0);
            this.vocalizeBtn.setOrigin(0.5, 0.5);
            this.vocalizeBtn.setInteractive({ cursor: 'pointer' });
            setupButtonFrameBased(this.vocalizeBtn, 'reading-sound', 1, 0, false, true);
            this.pageNumberText = new Phaser.GameObjects.Text(this.scene, 225, 435 - 227, this.displayedNumber / 2, {
                "fontFamily": "QuranErafont",
                "fontSize": "1.1rem",
                "color": "#010101",
                "align": 'center'
            });
            this.pageNumberText.setOrigin(0.5, 1);
            this.pageNumberText.visible = false;
            this.contentContainer.setPosition(-136, -30);
            this.contentContainer.add([this.pageBackground, /*this.frameBg,*/ this.text, this.vocalizeBtn, this.pageNumberText]);
            this.vocalizeBtn.on('pointerup', () => {
                if (this.sound) {
                    this.sound.stop();
                    this.sound.destroy();
                }
                if (gameplayConfig.data.useLocalFiles) {
                    this.sound = this.scene.sound.add(pages[this.dataIndex].text);
                }
                else {
                    this.sound = this.scene.sound.add(pages[this.dataIndex].audio);
                }
                this.sound.play();
            });
        }
    }
    (function (rp) {
        var scene;
        (function (scene) {
            class Boot extends Phaser.Scene {
                init() {
                    this.game.scale.transformX = (pageX) => {
                        let offsetLeft = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetLeft) {
                                offsetLeft = parentElement.offsetLeft;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageX - offsetLeft) * this.game.scale.displayScale.x;
                    };
                    this.game.scale.transformY = (pageY) => {
                        let offsetTop = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetTop) {
                                offsetTop = parentElement.offsetTop;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageY - offsetTop) * this.game.scale.displayScale.y;
                    };
                }

                create() {
                    game.scene.remove('Boot');
                    game.scene.add('Preloader', rp.scene.Preloader, true);
                }
            }
            scene.Boot = Boot;
        })(scene = rp.scene || (rp.scene = {}));
    })(rp || (rp = {}));
    (function (rp) {
        var scene;
        (function (scene) {
            class MainScene extends Phaser.Scene {
                create() {
                    this.gameplayScreen = new rp.screen.GameplayScreen(this);
                    this.children.add(this.gameplayScreen);
                    this.gameplayScreen.showInstructionPage();
                }
            }
            scene.MainScene = MainScene;
        })(scene = rp.scene || (rp.scene = {}));
    })(rp || (rp = {}));
    (function (rp) {
        var scene;
        (function (scene) {
            class Preloader extends Phaser.Scene {
                preload() {
                    if (typeof rp.App.json == "string") this.load.json('gameplay', rp.App.json); else this.game.cache.json.add('gameplay', rp.App.json);
                }
                create() {
                    const gameplayConfig = this.game.cache.json.get('gameplay');
                    const pages = gameplayConfig.data.pages;
                    pages.forEach((page, index) => {
                        if (page["useImages"]) {
                            if (page["text"].includes('http') || page["text"].includes("assets")) {
                                this.load.image(page["text"], page["text"]);
                            }
                            else if (json.data.gameId) {
                                this.load.image(page["text"], "assets/games/recite-the-verse/assets/imgs/words/" + json.data.gameId + "/" + page["text"] + ".png");
                            }
                            else {
                                this.load.image(page["text"], "assets/games/recite-the-verse/assets/imgs/words/" + page["text"] + ".png");
                            }
                        }
                        if (gameplayConfig.data.useLocalFiles) {
                            if (json.data.gameId) {
                                if (json.data.indexed) {
                                    this.load.audio(page["text"], "assets/games/recite-the-verse/assets/sound/mp3/words/" + json.data.gameId + "/" + (index + 1).toString() + ".mp3");
                                } else {
                                    this.load.audio(page["text"], "assets/games/recite-the-verse/assets/sound/mp3/words/" + json.data.gameId + "/" + page["text"] + ".mp3");
                                }
                            } else {
                                this.load.audio(page["text"], "assets/games/recite-the-verse/assets/sound/mp3/words/" + page["text"] + ".mp3");
                            }
                        } else {
                            this.load.audio(page["audio"], page["audio"]);
                        }
                    });

                    //modify pages array
                    let _pages = gameplayConfig.data.pages;
                    for (let i = _pages.length - 1; i >= 0; i--) {
                        _pages.splice(i, 0, { 'text': '' });
                    }
                    // gameplayConfig.data.pages = [_pages[0],_pages[1],_pages[2],_pages[3],_pages[4],_pages[5]];

                    this.load.audio(gameplayConfig.introduction.audio, gameplayConfig.introduction.audio);
                    this.load.audio("complete-audio", Phaser.Utils.Array.GetRandom(gameplayConfig.completeAudios));
                    this.load.pack('preloader', 'assets/games/recite-the-verse/assets/pack.json');
                    this.load.on('progress', (value) => {
                    }, this);
                    this.load.on('complete', () => {
                        this.nextScene();
                    });
                    this.load.start();
                }
                nextScene() {
                    game.scene.remove('Preloader');
                    game.scene.add('ScreenMain', rp.scene.MainScene, true);
                }
            }
            scene.Preloader = Preloader;
        })(scene = rp.scene || (rp.scene = {}));
    })(rp || (rp = {}));
    (function (rp) {
        var screen;
        (function (screen) {
            class AreYouSureWindow extends Phaser.GameObjects.Container {
                constructor(scene, onYes, onNo) {
                    super(scene);
                    this._areYouSurePage = new Phaser.GameObjects.Image(this.scene, -105, 0 - 48, 'Exit warning');
                    this._areYouSurePage.setOrigin(0, 0);
                    this._areYouSurePage.setInteractive();
                    this._btnSureYes = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 - 95, 485 - 50, 'btnYES1');
                    this._btnSureYes.setInteractive({ cursor: 'pointer' });
                    setupButtonTextureBased(this._btnSureYes, 'btnYES1', 'btnYES2');
                    this._btnSureYes.once('pointerup', () => {
                        onYes;
                        window.angularComponentRef.backToMenu();
                    });

                    var modalTextStyle = {
                        backgroundColor: 'transparent',
                        stroke: '#ffffff',
                        strokeThickness: 2,
                        lineSpacing: 15,
                        fontFamily: 'Kids Rock DEMO',
                        fontSize: 27,
                        color: '#43425d',
                        wordWrap: true,
                        align: 'center'
                    };
                    var modalText = this.scene.add.text(game.scale.width / 2 + 12, game.scale.height / 2, "Your progress in this activity will not be saved. \n Are you sure you want to exit? ", modalTextStyle);
                    modalText.setOrigin(0.5, 0.5);

                    this._btnSureNo = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 + 95, 485 - 50, 'btnNO1');
                    this._btnSureNo.setInteractive({ cursor: 'pointer' });
                    setupButtonTextureBased(this._btnSureNo, 'btnNO1', 'btnNO2');
                    this._btnSureNo.once('pointerup', onNo);
                    this.add(this._areYouSurePage);
                    this.add(this._btnSureYes);
                    this.add(this._btnSureNo);
                    this.add(modalText);
                }
            }
            screen.AreYouSureWindow = AreYouSureWindow;
        })(screen = rp.screen || (rp.screen = {}));
    })(rp || (rp = {}));
    (function (rp) {
        var screen;
        (function (screen) {
            class InstructionPage extends Phaser.GameObjects.Container {
                constructor(scene, onPlayClick, onSndClick) {
                    super(scene);
                    this._instructionPage = new Phaser.GameObjects.Image(this.scene, 0 - 105, 0 - 48, 'Instructions page  ALL ACTIVITY  TITLEs');
                    this._instructionPage.setOrigin(0, 0);
                    this._instructionPage.setInteractive();
                    this._instructionPageTitle = new Phaser.GameObjects.Image(this.scene, 495, 105, 'welcome');
                    this._btnPlay = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, 480 - 20, 'btnPLAY1');
                    this._btnPlay.setInteractive({ cursor: 'pointer' });
                    this._btnPlay.once('pointerup', () => {
                        onPlayClick();
                        // this.parentContainer.remove(this);
                    });
                    setupButtonTextureBased(this._btnPlay, 'btnPLAY1', 'btnPLAY2');
                    const gameplayConfig = this.scene.game.cache.json.get('gameplay');
                    this.instrTxt = this.scene.add.text(game.scale.width / 2, game.scale.height / 2, gameplayConfig.introduction.text ? gameplayConfig.introduction.text.replaceAll('<br>', '\n') : "Read the verse and earn reward for each letter you have read.", {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 27,
                        "color": "#43425D",
                        "align": 'center',
                        stroke: '#ffffff',
                        strokeThickness: 2,
                        lineSpacing: 20,
                    });
                    this.instrTxt.setOrigin(0.5, 0.5);
                    this.instrTxt.setWordWrapWidth(600);
                    this.instrTxt.setLineSpacing(5);
                    this._btnSoundInstruction = new Phaser.GameObjects.Image(this.scene, 800 - 105, 156 - 50, 'Sound');
                    this._btnSoundInstruction.setInteractive({ cursor: 'pointer' });
                    this._btnSoundInstruction.on('pointerup', () => {
                        onSndClick();
                        /* if (this.sound) {
                            this.sound.stop();
                            this.sound.destroy();
                        }
                        this.sound = this.scene.sound.add(gameplayConfig.introduction.audio);
                        this.sound.play(); */
                    });
                    this._btnSoundInstruction["defScale"] = this._btnSoundInstruction.scale;
                    setupButtonTextureBased(this._btnSoundInstruction, 'Sound', 'Sound HOVER EFFECT');
                    this.add(this._instructionPage);
                    this.add(this._instructionPageTitle);
                    this.add(this.instrTxt);
                    this.add(this._btnPlay);
                    this.add(this._btnSoundInstruction);
                }
            }
            screen.InstructionPage = InstructionPage;
        })(screen = rp.screen || (rp.screen = {}));
    })(rp || (rp = {}));
    (function (rp) {
        var screen;
        (function (screen) {
            class CompleteWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay, onNext) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-104.5, -48);
                    this._bgComplete = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Completion page LATEST UPDATED');
                    this._bgComplete.setOrigin(0, 0);
                    this._bgComplete.setInteractive();
                    this._cup = new Phaser.GameObjects.Image(this.scene, 400, 410, 'Trophy');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 570, 570, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 720, 570, 'btnReplay1');
                    this._btnNext = new Phaser.GameObjects.Image(this.scene, 870, 570, 'btnNEXT1');
                    let _CollectedPoints = new Phaser.GameObjects.Image(this.scene, 620, 440, 'Collected Points');
                    this.totalScoreTxt = this.scene.add.text(845, 352, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(648, 433, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bgComplete,
                        _CollectedPoints,
                        this._cup,
                        this._btnBack,
                        this._btnReplay,
                        this._btnNext,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        window.angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    this._btnNext.setInteractive({ cursor: 'pointer' });
                    setupButtonTextureBased(this._btnNext, 'btnNEXT1', 'btnNEXT2');
                    this._btnNext.on('pointerup', () => {
                        onNext(this._btnNext);
                        window.angularComponentRef.nextActivity();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                }
                show(score, starScore) {
                    window.angularComponentRef.gameFinish({ score, starScore });
                    this._cup.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._cup,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(starScore);
                    this.music = this.scene.sound.add("complete-audio");
                    this.music.play();
                }
            }
            screen.CompleteWindow = CompleteWindow;
        })(screen = rp.screen || (rp.screen = {}));
    })(rp || (rp = {}));
    (function (rp) {
        var screen;
        (function (screen) {
            class GameplayScreen extends Phaser.GameObjects.Container {
                constructor(scene) {
                    super(scene);
                    this.currentPageIndex = 0;
                    this.flippingActive = false;
                    this.flippingStage = 0;
                    this.flippingDirection = 0;
                    this.leftPage = null;
                    this.rightPage = null;
                    this.flippedPage = null;
                    this.unflippedPage = null;
                    window["gameplay"] = this;
                    this.createBackground();
                    this.buildPages();
                    this.buildButtons();
                }
                buildPages() {
                    this.pages = [];
                    /* introduction page */
                    // this.pages.push(new IntroductionPage(this.scene, 0));
                    /* empty page to combine with an introduction page */
                    // this.pages.push(new EmptyPage(this.scene, 1));
                    /* pages */
                    const gameplayConfig = this.scene.game.cache.json.get('gameplay');
                    const pages = gameplayConfig.data.pages;
                    for (let i = 0; i < pages.length; i++) {
                        this.pages.push(new Page(this.scene, i + 2, i, `${i + 1}`));
                    }
                    /* empty page */
                    // this.pages.push(new EmptyPage(this.scene, this.pages.length));
                    /* the last page */
                    // this.pages.push(new EndPage(this.scene, this.pages.length, () => this.onCompleteClicked()));
                    this.pagesContainer = new Phaser.GameObjects.Container(this.scene, 500, 107 + 227);
                    this.add(this.pagesContainer);
                    this.pagesContainer.add(this.pages);
                    this.resetGame();
                }
                buildButtons() {
                    this.buttonPrevPage = new Phaser.GameObjects.Image(this.scene, 450, 380, 'arrows'); window['this.buttonPrevPage'] = this.buttonPrevPage;
                    this.buttonPrevPage.setOrigin(0, 0);
                    this.buttonPrevPage.setInteractive({ cursor: 'pointer' });
                    setupButtonFrameBased(this.buttonPrevPage, 'arrows', 2, 0, true, true);
                    this.buttonPrevPage.on('pointerup', this.switchToPrevPage, this);
                    this.buttonNextPage = new Phaser.GameObjects.Image(this.scene, 660, 380, 'arrows'); window['this.buttonNextPage'] = this.buttonNextPage;
                    this.buttonNextPage.setOrigin(0, 0);
                    this.buttonNextPage.setInteractive({ cursor: 'pointer' });
                    setupButtonFrameBased(this.buttonNextPage, 'arrows', 3, 1, true, true);
                    this.buttonNextPage.on('pointerup', this.switchToNextPage, this);
                    this.add([this.buttonPrevPage, this.buttonNextPage]);
                }
                createBackground() {
                    this.backgroundImage = this.scene.add.image(0, 0, 'reading-bg');
                    this.backgroundImage.setOrigin(0, 0);
                    this.buttonClose = new Phaser.GameObjects.Image(this.scene, 930, 50, 'x Button');
                    this.buttonClose.setInteractive({ cursor: 'pointer' });
                    setupButtonTextureBased(this.buttonClose, 'x Button', 'x Button HOVER EFFECT');
                    this.buttonClose.on('pointerup', this.onLeaveClicked, this);
                    this.add(this.buttonClose);
                }
                showInstructionPage() {
                    setPageBackground("bg-blue");
                    let playInstructionSound = () => {
                        if (this.sound) {
                            this.sound.stop();
                            this.sound.destroy();
                        }
                        const gameplayConfig = this.scene.game.cache.json.get('gameplay');
                        this.sound = this.scene.sound.add(gameplayConfig.introduction.audio ? gameplayConfig.introduction.audio : "Recite the verse - Instruction page audio");
                        this.sound.play();
                    };
                    this.instructionPage = new screen.InstructionPage(this.scene, (target) => {
                        // playBtnClickAnim(target);
                        this.remove(this.instructionPage, true);
                        if (this.sound) {
                            this.sound.stop();
                        }

                        let currPage = this.pages[this.currentPageIndex + 1];
                        this.sound = this.scene.sound.add("Aoothu and basmala");
                        this.sound.play();
                        this.sound.once('complete', () => {
                            this.buttonPrevPage.setInteractive({ cursor: 'pointer' });
                            this.buttonNextPage.setInteractive({ cursor: 'pointer' });
                            currPage.vocalizeBtn.setInteractive({ cursor: 'pointer' });
                            this.buttonPrevPage.alpha = this.buttonNextPage.alpha = currPage.vocalizeBtn.alpha = 1;
                        });
                        this.buttonPrevPage.disableInteractive();
                        this.buttonNextPage.disableInteractive();
                        currPage.vocalizeBtn.disableInteractive();
                        this.buttonPrevPage.alpha = this.buttonNextPage.alpha = currPage.vocalizeBtn.alpha = 0.5;
                    }, (target) => {
                        // playBtnClickAnim(target);
                        playInstructionSound();
                    });
                    this.add(this.instructionPage);
                    try {
                        playInstructionSound();
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
                switchToPrevPage() {
                    if (this.flippingActive)
                        return;
                    if (this.currentPageIndex >= 2) {
                        this.flipToPrevPage();
                    }
                }
                switchToNextPage() {
                    if (this.flippingActive)
                        return;
                    if (this.currentPageIndex < this.pages.length - 2) {
                        this.flipToNextPage();
                    } else {//the last page's next button clicked
                        this.onCompleteClicked();
                    }
                }
                flipToPrevPage() {
                    this.leftPage = this.pages[this.currentPageIndex - 2];
                    this.flippedPage = this.pages[this.currentPageIndex];
                    this.unflippedPage = this.pages[this.currentPageIndex - 1];
                    this.rightPage = this.pages[this.currentPageIndex + 1];
                    this.flippingDirection = -1;
                    this.currentPageIndex -= 2;
                    this.flipPages(this.flippedPage, this.unflippedPage);

                    this.leftPage.setVisible(false);
                    this.rightPage.setVisible(true);
                }
                flipToNextPage() {
                    this.leftPage = this.pages[this.currentPageIndex];
                    this.flippedPage = this.pages[this.currentPageIndex + 1];
                    this.unflippedPage = this.pages[this.currentPageIndex + 2];
                    this.rightPage = this.pages[this.currentPageIndex + 3];
                    this.flippingDirection = 1;
                    this.currentPageIndex += 2;
                    this.flipPages(this.flippedPage, this.unflippedPage);

                    this.leftPage.setVisible(false);
                    this.rightPage.setVisible(true); window['this.rightPage'] = this.rightPage;
                }
                flipPages(pageToFlip, pageToUnflip) {
                    this.scene.game.sound.stopAll();
                    if (!this.flipSound)
                        this.flipSound = this.scene.sound.add('flip');
                    this.flipSound.play();
                    this.flippingActive = true;
                    this.flippingStage = 1;
                    this.updatePagesVisibility();
                    this.sortPages();
                    if (this.flippingDirection < 0) pageToFlip.setVisible(false);
                    this.scene.tweens.add({
                        targets: pageToFlip,
                        alpha: 0.0,
                        duration: GameplayScreen.FLIP_DURATION,
                        onComplete: () => {
                            this.flippingStage = 2;
                            this.updatePagesVisibility();
                            this.sortPages();
                            if (this.flippingDirection > 0) pageToUnflip.setVisible(false);
                            this.scene.tweens.add({
                                targets: pageToUnflip,
                                alpha: 1,
                                duration: GameplayScreen.UNFLIP_DURATION,
                                onComplete: () => {
                                    this.flippingActive = false;
                                    this.flippingStage = 0;
                                    this.flippingDirection = 0;
                                    this.detectPages();
                                    this.updatePagesVisibility();
                                    this.sortPages();
                                }
                            });
                        }
                    });
                }
                sortPages() {
                    this.pagesContainer.sort("pageIndex", (pageA, pageB) => {
                        if (pageA === this.flippedPage || pageA === this.unflippedPage)
                            return 1;
                        if (pageB === this.flippedPage || pageB === this.unflippedPage)
                            return -1;
                        return this.flippingDirection * (pageA.pageIndex - pageB.pageIndex);
                    });
                }
                detectPages() {
                    this.leftPage = this.pages[this.currentPageIndex];
                    this.rightPage = this.pages[this.currentPageIndex + 1];
                    this.flippedPage = this.unflippedPage = null;

                    this.leftPage.setVisible(false);
                    this.rightPage.setVisible(true);
                }
                updatePagesVisibility() {
                    this.pages.forEach(page => page.setVisible(false));
                    if (this.leftPage)
                        this.leftPage.visible = true;
                    if (this.rightPage)
                        this.rightPage.visible = true;
                    if (this.flippedPage)
                        this.flippedPage.visible = this.flippingStage === 1;
                    if (this.unflippedPage)
                        this.unflippedPage.visible = this.flippingStage === 2;

                    this.leftPage.setVisible(false);
                }
                resetGame() {
                    this.pages.forEach(page => page.alpha = 1);
                    this.currentPageIndex = 0;
                    this.flippingActive = false;
                    this.flippingStage = 0;
                    this.detectPages();
                    this.updatePagesVisibility();
                    this.sortPages();
                }
                onCompleteClicked() {
                    const numStars = this.scene.game.cache.json.get('gameplay').maxStars;
                    let completeWindow = new screen.CompleteWindow(this.scene, (target) => {
                    }, (target) => {
                        completeWindow.destroy();
                        this.resetGame();
                        this.remove(completeWindow);
                    }, (target) => {
                    });
                    this.add(completeWindow);
                    completeWindow.show(0, numStars);
                }
                onLeaveClicked() {
                    this.showAreYouSurePage();
                    this.scene.sound.add('warning page pop up sfx').play();
                }
                destroyGameplay() {
                    this.destroy();
                    this.backgroundImage.destroy();
                }
                showAreYouSurePage() {
                    game.sound.pauseAll();
                    const areYouSureWindow = new screen.AreYouSureWindow(this.scene, () => {
                        areYouSureWindow.destroy();
                        this.resetGame();
                        this.destroyGameplay();
                    }, () => {
                        areYouSureWindow.destroy();
                        game.sound.resumeAll();
                    });
                    this.add(areYouSureWindow);
                }
            }
            GameplayScreen.FLIP_DURATION = 275;
            GameplayScreen.UNFLIP_DURATION = 275;
            screen.GameplayScreen = GameplayScreen;
        })(screen = rp.screen || (rp.screen = {}));
    })(rp || (rp = {}));

    game = new rp.App(json);
};

function destroyreadingReciteTheVerse() {
    if (game) {
        game.destroy();
        game = null;
    }
}
