var game = null;

var bootState = function (game) {
};

bootState.prototype = {

    preload: function () {
        var d = new Date();
        var t = d.getTime();

        this.load.baseURL = "assets/games/shooting-hoops/assets/";
        this.load.image('loadingbar_border', 'ui/loadingbar_border.png?v=' + t);
        this.load.image('loadingbar', 'ui/loadingbar.png?v=' + t);
    },
    create: function () {
        this.scene.start("preload");
    }

};

var preloadState = function (game) {
};

preloadState.prototype = {
    init: function () {
        this.centerX = this.game.config.width * 0.5;
        this.centerY = this.game.config.height * 0.5;
    },
    preload: function () {
        this.progressTxt = this.add.text(game.scale.width / 2, game.scale.height / 2, "", {
            "fontFamily": "QuranEra",
            "fontSize": 25,
            "color": "#000000",
            "align": 'center'
        });
        this.progressTxt.setOrigin(0.5, 0.5);

        this.t = new Date().getTime();
        var t = this.t;
        this.load.baseURL = "assets/games/shooting-hoops/assets/";

        this.load.image("bg0", "bg0.png?v=" + t);

        this.load.image("ball_tally", "ui/ball_tally.png?v=" + t);
        this.load.image("ball_tally_10", "ui/ball_tally_10.png?v=" + t);
        this.load.image("tally_ball", "ui/tally_ball.png?v=" + t);
        this.load.image("tally_circle", "ui/tally_circle.png?v=" + t);

        this.load.json('buttonsData', 'ui/buttons.json?v=' + t);
        this.load.image("ball", "ball.png?v=" + t);

        this.load.image("stand", "stand.png?v=" + t);
        this.load.image("ring", "ring.png?v=" + t);
        this.load.image("ground", "ground.png?v=" + t);

        this.load.image("hit_area", "hit_area.png?v=" + t);
        this.load.image("text_hit_area", "text_hit_area.png?v=" + t);

        this.load.image("shooting_hoops_title", "shooting_hoops_title.png");
        this.load.image("hand", "hand.png");


        //welcome

        this.load.image("greyRect", "greyRect.png?v=" + t);
        this.load.image("roundRect", "roundRect.png?v=" + t);
        this.load.image("titlerect", "titlerect.png?v=" + t);

        this.load.image("snail", "snail.png?v=" + t);
        this.load.image("welcomeText", "welcomeText.png?v=" + t);
        this.load.image("leaf", "leaf.png?v=" + t);
        this.load.image("leaf2", "leaf2.png?v=" + t);

        //loose
        this.load.image("looseBg", "looseBg.png?v=" + t);
        this.load.image("tryagainBoard", "tryagainBoard.png?v=" + t);

        this.load.image("badge", "badge.png?v=" + t);
        this.load.image("bigstar", "bigstar.png?v=" + t);
        this.load.image("ribbon", "ribbon.png?v=" + t);
        this.load.image("totalScoreText", "totalScoreText.png?v=" + t);

        // win

        this.load.image("congratsTitle", "congratsTitle.png?v=" + t);

        this.load.image("winBoard", "tryagainBoard.png?v=" + t);
        this.load.image("completionBg", "completionBg.png?v=" + t);
        this.load.image("ribboncomplete", "ribboncomplete.png?v=" + t);

        this.load.image("trophy", "trophy.png?v=" + t);
        this.load.image("winstar", "winstar.png?v=" + t);
        this.load.image("star1", "star1.png?v=" + t);
        this.load.image("star1", "star1.png?v=" + t);

        //warning

        this.load.image("warningbg", "warningbg.png?v=" + t);
        this.load.image("warningboard", "warningboard.png?v=" + t);
        this.load.image("sign", "sign.png?v=" + t);

        //sprite Sheets

        this.load.atlas('buttons', 'ui/buttons.png?v=' + t, 'ui/buttons.json?v=' + t);
        this.load.atlas('closeButton', 'ui/closeButton.png?v=' + t, 'ui/closeButton.json?v=' + t);

        //audio

        this.load.audio("win_sound", "audio/win.mp3?v=" + t);
        this.load.audio("fail_sound", "audio/fail.mp3?v=" + t);

        this.load.audio("Draganddropinstruction", "audio/Draganddropinstruction.mp3?v=" + t);

        this.load.audio("buttonclick", "audio/buttonclick.mp3?v=" + t);
        this.load.audio("buttonhover", "audio/buttonhover.mp3?v=" + t);

        this.load.audio("bgmusic", "audio/bgmusic.mp3?v=" + t);
        this.load.audio("throwing_ball", "audio/throwing_ball.mp3?v=" + t);
        this.load.audio("warning", "audio/warning.mp3?v=" + t);

        this.load.audio("correct_click_1", "audio/correct_click_1.mp3?v=" + t);
        this.load.audio("correct_click_2", "audio/correct_click_2.mp3?v=" + t);
        this.load.audio("wrong_click", "audio/wrong_click.mp3?v=" + t);

        this.load.audio("activity_completion", "audio/activity_completion.mp3?v=" + t);

        this.load.on('progress', this.updateBar, this);

    },
    updateBar: function (p) {
        this.progressTxt.text = Math.ceil(p * 100) + "%";
    },
    create: function () {
        preloadState.obj = this;
        var t = this.t;
        send(Global.json, this.onResponse);
    },
    onResponse: function () {
        preloadState.obj.scene.start("welcome"); //welcome
    }

};

var welcomeState = function (game) {

};

welcomeState.prototype = {
    preload: function () {
        for (var i = 0; i < Global.questionsArr.length; i++) {
            var question = Global.questionsArr[i];
            if (question.questionImg != "") {
                if (!question.questionImg.startsWith("http")) {
                    this.load.image("Q" + (i + 1), "assets/games/shooting-hoops/assets/imgs/words/" + question.questionImg + ".png");
                }
                else {
                    this.load.image("Q" + (i + 1), question.questionImg);
                }
            }
            for (var j = 0; j < Global.optionsArr[i].length; j++) {
                var option = Global.optionsArr[i][j];

                if (option.img != "") {

                    if (!option.img.startsWith("http")) {
                        this.load.image("Q" + (i + 1) + "_op" + (j + 1), "assets/games/shooting-hoops/assets/imgs/words/" + option.img + ".png");
                    }
                    else {
                        this.load.image("Q" + (i + 1) + "_op" + (j + 1), option.img);
                    }
                }
            }
        }
    },

    init: function () {
        this.instructionSound = this.sound.add("Draganddropinstruction");

        this.clickSound = this.sound.add("buttonclick");
        this.hoverSound = this.sound.add("buttonhover");

    },
    create: function () {

        this.centerX = this.game.config.width * 0.5;
        this.centerY = this.game.config.height * 0.5;

        var greyRect = this.add.sprite(0, 0, "greyRect");
        greyRect.setOrigin(0);

        var leaf = this.add.sprite(111.05, 428.1, "leaf");
        var leaf2 = this.add.sprite(877.25, 174.05, "leaf2");

        var roundRect = this.add.sprite(this.centerX, this.centerY, "roundRect");
        var titlerect = this.add.sprite(this.centerX, 102, "titlerect");
        var welcomeText = this.add.sprite(this.centerX, 102, "shooting_hoops_title");
        welcomeText.setScale(0.8);

        var playBtn = this.add.sprite(this.centerX, 459.1, "buttons", "playBtn");
        playBtn.setInteractive();


        this.soundButton = this.add.sprite(719.15, 97.5, "buttons", "soundBtn");
        this.soundButton.setInteractive();

        playBtn.on('pointerdown', this.onButtonClick, this);

        playBtn.on('pointerover', function () {
            this.onHover(playBtn, "playBtnHover");
        }, this);
        playBtn.on('pointerout', function () {
            this.onHover(playBtn, "playBtn");
        }, this);

        this.soundButton.on('pointerdown', this.onSoundButtonClick, this);
        this.soundButton.on('pointerover', function () {
            this.onHover(this.soundButton, "soundBtnHover")
        }, this);
        this.soundButton.on('pointerout', function () {
            this.onHover(this.soundButton, "soundBtn")
        }, this);

        //var str = "This quiz has 15 questions.\n\nYou need to pass the quiz to \n\nget through to the next zone.\n\nGood Luck!";
        var str = "Shoot the ball into the hoop \n\nby matching the words.";
        var style = {fontFamily: 'Kids Rock DEMO', fontSize: 28, color: '#43425D', align: 'center'/*, strokeThickness: 2*/ };

        var paragraph = this.add.text(this.centerX + 10, this.centerY, str, style);
        paragraph.setOrigin(0.5, 0.5);

        try {
            this.instructionSound.play();
        } catch (e) {
            console.error(e);
        }

        /*var tween = this.tweens.add({
            targets: playBtn,
            scale:0.8,
            duration: 100,
            ease: 'Power2',
            yoyo: true,
            paused: true
        });

           var tween1 = this.tweens.add({
            targets: playBtn,
            scale:1.1,
            duration: 100,
            ease: 'Power2',
            paused: true,
        });*/


    },
    onButtonClick: function () {
        this.instructionSound.stop();

        this.playSound("clickSound");
        this.scene.start("game");
    },
    onHover: function (gameObject, frame) {
        var str = frame.substr(frame.length - 5, frame.length);
        if (str === "Hover") {
            this.playSound("hoverSound");
        }

        gameObject.setFrame(frame);
    },
    onSoundButtonClick: function () {
        this.playSound("clickSound");
        this.instructionSound.play();
        //this.instructionSound.play();

    },

    playSound: function (str) {
        if (Global.soundOnFlag === false) {
            return;
        }
        if (str === "clickSound") {
            this.clickSound.play();
        } else if (str === "hoverSound") {
            this.hoverSound.play();
        }
    }

};

var looseState = function (game) {

};

looseState.prototype = {

    create: function () {
        Global.score = 0;

        var failsound = this.sound.add('fail_sound');
        failsound.play();


        this.centerX = this.game.config.width * 0.5;
        this.centerY = this.game.config.height * 0.5;

        this.clickSound = this.sound.add("buttonclick");
        this.hoverSound = this.sound.add("buttonhover");

        var looseBg = this.add.sprite(0, 0, "looseBg");
        looseBg.setOrigin(0);
        var tryagainBoard = this.add.sprite(this.centerX, this.centerY, "tryagainBoard");

        var ribbon = this.add.sprite(490, 204, "ribbon");

        var bigstar = this.add.sprite(273.05, 354.1, "bigstar");
        bigstar.setScale(0);

        var badge = this.add.sprite(this.game.config.width / 2 - 15, this.game.config.height / 2 + 100, "badge");

        var totalScoreText = this.add.sprite(this.game.config.width / 2 + 30, this.game.config.height / 2 - 10, "totalScoreText");

        var textStyle = { fontSize: "28px", fontFamily: 'Kids Rock DEMO', align: "center", fill: "#fed210", stroke: "#91301a", strokeThickness: 7 }

        var scoreField = this.add.text(this.game.config.width / 2 + 170, this.game.config.height / 2 - 5,
            String(window.angularComponentRef ? window.angularComponentRef.component.userInfo.totalScore : 0),
            textStyle);
        scoreField.setOrigin(0, 0.5);
        scoreField.setScale(0);

        var textStyle1 = { fontSize: "22px", fontFamily: "Kids Rock DEMO", align: "center", fill: "#ffffff", stroke: "#ffffff" }

        var badgeField = this.add.text(this.game.config.width / 2, this.game.config.height / 2 + 90, "+" + Global.score.toString(), textStyle1);
        badgeField.setOrigin(0.5, 0.5);
        badgeField.setLineSpacing(10);
        badgeField.setStroke("#ffffff", 0.2);

        var backtoMenu = this.add.sprite(457, 520, "buttons", "backtomenu");

        backtoMenu.setInteractive();
        backtoMenu.on('pointerdown', this.onBackButtonClick, this);

        backtoMenu.on('pointerover', function () {
            this.onHover(backtoMenu, "backtomenuHover");
        }, this);
        backtoMenu.on('pointerout', function () {
            this.onHover(backtoMenu, "backtomenu");
        }, this);

        var playAgainBtn = this.add.sprite(609, 520, "buttons", "playagainBtn");


        var tween = this.tweens.add({
            targets: bigstar,
            scale: 1,
            ease: 'Power1',
            duration: 500,
        });

        var tween2 = this.tweens.add({
            targets: scoreField,
            scale: 1,
            ease: 'Power1',
            duration: 200,
        });


        /*var tween = this.tweens.add({
            targets: playAgainBtn,
            scale:0.8,
            duration: 100,
            ease: 'Power2',
            yoyo: true,
            paused: true
        });

           var tween1 = this.tweens.add({
            targets: playAgainBtn,
            scale:1.1,
            duration: 100,
            ease: 'Power2',
            paused: true,
        });*/

        playAgainBtn.setInteractive();
        playAgainBtn.on('pointerdown', this.onPlayAgainButtonClick, this);

        playAgainBtn.on('pointerover', function () {
            this.onHover(playAgainBtn, "playagainBtnHover");
        }, this);
        playAgainBtn.on('pointerout', function () {
            this.onHover(playAgainBtn, "playagainBtn");
        }, this);

    },
    onPlayAgainButtonClick: function () {
        this.playSound("clickSound");
        this.scene.start("welcome");
    },
    onBackButtonClick: function () {
        this.playSound("clickSound");
        //this.scene.start("welcome");
        if (window.angularComponentRef) window.angularComponentRef.backToMenu();
    },
    onHover: function (gameObject, frame) {
        var str = frame.substr(frame.length - 5, frame.length);
        if (str === "Hover") {
            this.playSound("hoverSound");
        }
        gameObject.setFrame(frame);
    },
    playSound: function (str) {
        if (Global.soundOnFlag === false) {
            return;
        }
        if (str === "clickSound") {
            this.clickSound.play();
        } else if (str === "hoverSound") {
            this.hoverSound.play();
        }
    }

};

var gameState = function (game) {
};

var winState = function (game) {

};


winState.prototype = {

    init: function () {
        this.activityCompletion = this.sound.add("activity_completion");
        if (window.angularComponentRef) window.angularComponentRef.gameFinish({ score: Global.score });
    },
    create: function () {
        this.activityCompletion.play();

        this.centerX = this.game.config.width * 0.5;
        this.centerY = this.game.config.height * 0.5;

        this.clickSound = this.sound.add("buttonclick");
        this.hoverSound = this.sound.add("buttonhover");

        var winBg = this.add.sprite(0, 0, "completionBg");
        winBg.setOrigin(0);

        var tryagainBoard = this.add.sprite(this.centerX, this.centerY, "winBoard");

        var ribboncomplete = this.add.sprite(this.centerX, 190.05, "ribboncomplete");

        var trophy = this.add.sprite(273.05, 354.1, "trophy");
        trophy.setScale(0);
        var badge = this.add.sprite(this.game.config.width / 2 - 15, this.game.config.height / 2 + 100, "badge");


        var tween = this.tweens.add({
            targets: trophy,
            scale: 1,
            ease: 'Power1',
            duration: 500,
        });



        var winstar = this.add.sprite(485.1, 48, "winstar");

        var totalScoreText = this.add.sprite(this.game.config.width / 2 + 30, this.game.config.height / 2 - 10, "totalScoreText");

        var style = {
            fontSize: "28px",lineSpaceng:25, fontFamily: "Kids Rock DEMO", align: "center",
            fill: "#fed210", stroke: "#91301a", strokeThickness: 7
        }

        var scoreField = this.add.text(650, 298, String(window.angularComponentRef ? window.angularComponentRef.component.userInfo.totalScore : 0), style);
        scoreField.setOrigin(0, 0.5);
        scoreField.setScale(0);

        var tween2 = this.tweens.add({
            targets: scoreField,
            scale: 1,
            ease: 'Power1',
            duration: 200,
        });

        var style2 = { fontFamily: "Kids Rock DEMO", fontSize: 23, color: '#ffffff', align: 'left' };

        var badgeField = this.add.text(489.95, 390.5, "+" + Global.score.toString(), style2);
        badgeField.setOrigin(0.5, 0.5);
        badgeField.setLineSpacing(10);
        badgeField.setStroke("#ffffff", 0.2);


        var backtoMenu = this.add.sprite(this.game.config.width / 2 - 35, this.game.config.height / 2 + 220, "buttons", "backtomenu");
        backtoMenu.setInteractive();

        var playAgainBtn = this.add.sprite(this.game.config.width / 2 + 120, this.game.config.height / 2 + 220, "buttons", "playagainBtn");
        playAgainBtn.setInteractive();

        var nextBtn = this.add.sprite(this.game.config.width / 2 + 280, this.game.config.height / 2 + 220, "buttons", "nextBtn");
        nextBtn.setInteractive();


        playAgainBtn.on('pointerdown', this.onPlayButtonClick, this);
        backtoMenu.on('pointerdown', this.onBackButtonClick, this);
        nextBtn.on('pointerdown', this.onNextButtonClick, this);

        playAgainBtn.on('pointerover', function () { this.onHover(playAgainBtn, "playagainBtnHover"); }, this);
        playAgainBtn.on('pointerout', function () { this.onHover(playAgainBtn, "playagainBtn"); }, this);

        backtoMenu.on('pointerover', function () { this.onHover(backtoMenu, "backtomenuHover"); }, this);
        backtoMenu.on('pointerout', function () { this.onHover(backtoMenu, "backtomenu"); }, this);

        nextBtn.on('pointerover', function () { this.onHover(nextBtn, "nextBtnHover"); }, this);
        nextBtn.on('pointerout', function () { this.onHover(nextBtn, "nextBtn"); }, this);


    },
    onPlayButtonClick: function () {
        this.playSound("clickSound");

        this.scene.start("welcome");
    },
    onBackButtonClick: function () {
        this.playSound("clickSound");
        if (window.angularComponentRef) window.angularComponentRef.backToMenu()
    },
    onNextButtonClick: function () {
        this.playSound("clickSound");
        if (window.angularComponentRef) window.angularComponentRef.nextActivity();
    },
    onHover: function (gameObject, frame) {
        var str = frame.substr(frame.length - 5, frame.length);
        if (str === "Hover") { this.playSound("hoverSound"); }

        gameObject.setFrame(frame);
    },
    playSound: function (str) {
        if (Global.soundOnFlag === false) { return; }
        if (str === "clickSound") { this.clickSound.play(); }
        else if (str === "hoverSound") { this.hoverSound.play(); }
    }

};

var warningState = {

    warningPopup: function (_this, _visibleFlag) {
        _this.warningSound = _this.sound.add("warning");

        _this.warningContainer = _this.add.container(0, 0);
        _this.warningContainer.visible = _visibleFlag;

        this.gameState = _this;

        var warningBg = _this.add.sprite(0, 0, "warningbg");
        warningBg.setOrigin(0);
        _this.warningContainer.add(warningBg);

        var leaf = _this.add.sprite(111.05, 428.1, "leaf");
        _this.warningContainer.add(leaf);

        var leaf2 = _this.add.sprite(877.25, 174.05, "leaf2");
        _this.warningContainer.add(leaf2);

        var warningboard = _this.add.sprite(490, 300, "warningboard");
        _this.warningContainer.add(warningboard);

        var sign = _this.add.sprite(488.15, 174, "sign");
        _this.warningContainer.add(sign);

        var yesBtn = _this.add.sprite(389.1, 452.15, "buttons", "yesBtn");
        _this.warningContainer.add(yesBtn);

        var noBtn = _this.add.sprite(585.15, 452.15, "buttons", "noBtn");
        _this.warningContainer.add(noBtn);

        yesBtn.setInteractive();
        noBtn.setInteractive();

        yesBtn.on('pointerdown', this.onYesButtonClick, this);
        noBtn.on('pointerdown', this.onNoButtonClick, this);

        yesBtn.on('pointerover', function () { _this.onHover(yesBtn, "yesBtnHover"); }, _this);
        yesBtn.on('pointerout', function () { _this.onHover(yesBtn, "yesBtn"); }, _this);

        noBtn.on('pointerover', function () { _this.onHover(noBtn, "noBtnHover"); }, _this);
        noBtn.on('pointerout', function () { _this.onHover(noBtn, "noBtn"); }, _this);

        var str = "Your progress in this activity will not be saved.\n\nAre you sure you want to exit?";
        //var style = { fontFamily: 'kidsrock', fontSize: 26, color: '#a25122',align:'center',stroke:'#ffffff', strokeThickness:2};
        var style2 = { fontFamily: 'Kids Rock DEMO', fontSize: 27, color: '#43425d', align: 'center', stroke: '#ffffff', strokeThickness: 2 };


        var paragraph = _this.add.text(170, 270, str, style2);
        _this.warningContainer.add(paragraph);

    },
    showWarning: function (_this) {

        _this.warningSound.play();

        _this.warningContainer.visible = true;
    },
    hideWarning: function (_this) {
        _this.warningSound.stop();
        _this.warningContainer.visible = false;
    },

    onYesButtonClick: function () {
        this.gameState.playSound("clickSound");
        if (window.angularComponentRef) window.angularComponentRef.backToMenu();
    },
    onNoButtonClick: function () {
        this.gameState.playSound("clickSound");
        this.hideWarning(this.gameState);
    }

};

gameState.prototype = {

    init: function () {
        gameState.obj = this;
        this.cx = this.cameras.main.centerX;
        this.cy = this.cameras.main.centerY;

        this.mouseStartX;
        this.mouseStartY;
        this.mouseEndX;
        this.mouseEndY;

        this.qCount = 0;
        this.totalQuestions = Global.actualQCnt;
        this.gameOverFlag = false;

        this.ballDownwardFlag = false;
        this.ballGroundFlag = false;
        this.shootedFlag = false;

        this.failCount = 0;

        this.selectedOption = "";

        this.scaleTween;

        this.tallyBallsArr = [];

        Global.score = 0;

    },
    create: function () {
        this.setScreen();

        this.updateQuestions();

        this.addUI();

        this.addPlatfoms();

        this.addEvents();


        this.clickSound = this.sound.add("buttonclick");
        this.hoverSound = this.sound.add("buttonhover");

        this.winSound1 = this.sound.add("correct_click_1");
        this.winSound2 = this.sound.add("correct_click_2");
        this.wrongSound = this.sound.add("wrong_click");
        this.throwing_ball = this.sound.add("throwing_ball");
        this.bgmusic = this.sound.add("bgmusic");
        this.bgmusic.setLoop(true);
        this.bgmusic.play();


        warningState.warningPopup(this, false);

    },

    setScreen: function () {
        var bg = this.add.image(0, 0, "bg0");
        bg.setScale(1.02);
        bg.setOrigin(0);

        var stand_1 = this.add.sprite(498 - 262, 260, "stand");
        var stand_2 = this.add.sprite(498, 260, "stand");
        var stand_3 = this.add.sprite(498 + 262, 260, "stand");

        this.ringGroup = this.add.group();

        this.ring_1 = this.ringGroup.create(486.35 - 262, 229, "ring");
        this.ring_2 = this.ringGroup.create(486.35, 229, "ring");
        this.ring_3 = this.ringGroup.create(486.35 + 262, 229, "ring");

        var textStyle = {
            font: "75px QuranEra",
            fill: '#000000',
            fontWeight: 'bold',
            align: 'center',
            stroke: "#ffffff",
            fixedWidth: 180,
            fixedHeight: 170
        };

        var opt_ypos = 158;

        this.optionA = {
            x: this.ring_1.x,
            y: opt_ypos,
            text: this.add.text(this.ring_1.x, opt_ypos, "", textStyle).setOrigin(0.5, 0.5).setLineSpacing(10),
            sprite: this.add.sprite(this.ring_1.x, opt_ypos - 30, "").setOrigin(0.5, 0.5),
            type: "optionA",
            id: 1
        };

        this.optionB = {
            x: this.ring_2.x,
            y: opt_ypos,
            text: this.add.text(this.ring_2.x, opt_ypos, "", textStyle).setOrigin(0.5, 0.5).setLineSpacing(10),
            sprite: this.add.sprite(this.ring_2.x, opt_ypos - 30, "").setOrigin(0.5, 0.5),
            type: "optionB",
            id: 2
        };

        this.optionC = {
            x: this.ring_3.x,
            y: opt_ypos,
            text: this.add.text(this.ring_3.x, opt_ypos, "", textStyle).setOrigin(0.5, 0.5).setLineSpacing(10),
            sprite: this.add.sprite(this.ring_3.x, opt_ypos - 30, "").setOrigin(0.5, 0.5),
            type: "optionC",
            id: 3
        };

        this.ball = this.matter.add.image(500, 480, "ball");
        this.ball.setCircle();
        this.ball.setFriction(0.005);
        this.ball.setBounce(0.3);
        this.ball.setStatic(true);
        this.ball.soundPlayed = false;

        this.ball.scaleX = 0.75;
        this.ball.scaleY = 0.75;
        this.ball.ringFlag = false;

        this.ball.ix = this.ball.x;
        this.ball.iy = this.ball.y;

        var qtextStyle = {
            font: "50px QuranEra",
            fill: '#000000',
            fontWeight: 'bold',
            align: 'center',
            stroke: "#ffffff",
            fixedWidth: 80,
            fixedHeight: 80
        };

        this.qField = {

            text: this.add.text(this.ball.x, this.ball.y, "", qtextStyle).setOrigin(0.5, 0.5),
            sprite: this.add.sprite(this.ball.x, this.ball.y, "").setOrigin(0.5, 0.5),
            answer: 0
        };

        this.ringGroupf = this.add.group();

        this.ring_1f = this.ringGroupf.create(486.35 - 262, 229, "ring");
        this.ring_2f = this.ringGroupf.create(486.35, 229, "ring");
        this.ring_3f = this.ringGroupf.create(486.35 + 262, 229, "ring");

        this.ring_1f.visible = false;
        this.ring_2f.visible = false;
        this.ring_3f.visible = false;

    },
    addPlatfoms: function () {

        this.ground = this.matter.add.image(489, 1500, 'ground', null, { restitution: 0.4, isStatic: true });

        this.platformA1 = this.matter.add.image(162.55, 232 + 2000, 'hit_area', null, {
            restitution: 0.4,
            isStatic: true
        });
        this.platformA2 = this.matter.add.image(290.60, 232 + 2000, 'hit_area', null, {
            restitution: 0.4,
            isStatic: true
        });

        this.platformB1 = this.matter.add.image(424.60, 232 + 2000, 'hit_area', null, {
            restitution: 0.4,
            isStatic: true
        });
        this.platformB2 = this.matter.add.image(552.65, 232 + 2000, 'hit_area', null, {
            restitution: 0.4,
            isStatic: true
        });

        this.platformC1 = this.matter.add.image(690.70, 232 + 2000, 'hit_area', null, {
            restitution: 0.4,
            isStatic: true
        });
        this.platformC2 = this.matter.add.image(817.75, 232 + 2000, 'hit_area', null, {
            restitution: 0.4,
            isStatic: true
        });

        this.ground.alpha = 0;
        this.platformA1.alpha = 0;
        this.platformA2.alpha = 0;
        this.platformB1.alpha = 0;
        this.platformB2.alpha = 0;
        this.platformC1.alpha = 0;
        this.platformC2.alpha = 0;

        this.platformA1.ix = this.platformA1.x;
        this.platformA2.ix = this.platformA2.x;

        this.platformB1.ix = this.platformB1.x;
        this.platformB2.ix = this.platformB2.x;

        this.platformC1.ix = this.platformC1.x;
        this.platformC2.ix = this.platformC2.x;

        this.matter.world.on('collisionstart', this.onCollision, this);
    },

    changePlatfomPos: function () {
        this.ground.y = 508;
        this.platformA1.y = 232;
        this.platformA2.y = 232;

        this.platformB1.y = 232;
        this.platformB2.y = 232;

        this.platformC1.y = 232;
        this.platformC2.y = 232;

        if (this.answer === false) {
            this.platformA1.x = this.ring_1.x - 23;
            this.platformA2.x = this.ring_1.x + 23;

            this.platformB1.x = this.ring_2.x - 23;
            this.platformB2.x = this.ring_2.x + 23;

            this.platformC1.x = this.ring_3.x - 23;
            this.platformC2.x = this.ring_3.x + 23;
        } else {

            this.platformA1.x = this.platformA1.ix;
            this.platformA2.x = this.platformA2.ix;

            this.platformB1.x = this.platformB1.ix;
            this.platformB2.x = this.platformB2.ix;

            this.platformC1.x = this.platformC1.ix;
            this.platformC2.x = this.platformC2.ix;
        }

    },
    resetPlatfoms: function () {
        this.ground.y = 1500;
        this.platformA1.y = 2232;
        this.platformA2.y = 2232;

        this.platformB1.y = 2232;
        this.platformB2.y = 2232;

        this.platformC1.y = 2232;
        this.platformC2.y = 2232;

    },
    onCollision: function (event, bodyA, bodyB) {
        var self = this;

        if (bodyA.gameObject.texture.key === "ball" && bodyB.gameObject.texture.key === "hit_area" && this.ball.soundPlayed === false) {
            this.ball.soundPlayed = true;

            if (this.answer === false) {
                this.playSound("wrong");
            }
        }

        var typeA = bodyA.gameObject.texture.key;
        var typeB = bodyB.gameObject.texture.key;

        if (typeA != "ground" && typeB != "ground") {
            return;
        }


        if (this.ballGroundFlag === false) {
            this.ballGroundFlag = true;

            setTimeout(function () {
                self.onBallFallingComplete();
            }, 1500);

        }

    },
    onBallFallingComplete: function () {
        if (this.qCount === this.totalQuestions - 1) {
            this.onGameOver("finished");
            return;
        }

        if (this.failCount === 3) {
            this.onGameOver("fail");
            return;
        }

        this.resetPlatfoms();

        this.ballGroundFlag = false;
        this.shootedFlag = false;
        this.ball.soundPlayed = false;

        this.ring_1f.visible = false;
        this.ring_2f.visible = false;
        this.ring_3f.visible = false;

        this.ring_1.visible = true;
        this.ring_2.visible = true;
        this.ring_3.visible = true;

        this.ball.setStatic(true);

        this.ball.x = 500;
        this.ball.y = 480;

        this.ball.scaleX = 0.75;
        this.ball.scaleY = 0.75;


        this.nextQuestion();
    },
    addUI: function () {

        var ballTally = {};

        if (Global.actualQCnt == 10) {
            ballTally = this.add.sprite(51, 294, "ball_tally_10");
        } else {
            ballTally = this.add.sprite(51, 246, "ball_tally");
        }

        this.tallyBallGroup = this.add.group();
        this.tallyCircleGroup = this.add.group();

        for (var i = 0; i < Global.actualQCnt; i++) {
            var xpos = 50.5;
            var ypos = 82.05 + i * 46.6;
            var ball = this.tallyBallGroup.create(xpos, ypos, "tally_ball");
            var circle = this.tallyCircleGroup.create(xpos, ypos, "tally_circle");

            ball.visible = false;
            circle.visible = false;
        }

        this.closeButton = this.add.sprite(933.2, 48.05, "closeButton", "closeButton");

        this.soundButton = this.add.sprite(933.2, 130, "buttons", "soundBtn");

        this.soundButton.visible = false;

        var handX = ({
            1: this.optionA,
            2: this.optionB,
            3: this.optionC
        })[this.qField.answer].sprite.x;

        this.hand = this.add.sprite(handX, 0, "hand");
        this.hand.setOrigin(0.5, 0);
        this.hand.scaleX = 0.8;
        this.hand.scaleY = 0.8;

        this.tweens.add({
            targets: this.hand,
            y: this.hand.y + 15,
            duration: 650,
            ease: 'Linear',
            yoyo: true,
            repeat: 1000
        });

    },
    addEvents: function () {
        this.ball.setInteractive();

        this.closeButton.setInteractive();
        this.closeButton.on('pointerdown', this.onCloseButtonClick, this);

        this.closeButton.on('pointerover', function () {
            this.onHover(this.closeButton, "closeButtonHover")
        }, this);
        this.closeButton.on('pointerout', function () {
            this.onHover(this.closeButton, "closeButton")
        }, this);

        this.optionA.type = "optionA";
        this.optionB.type = "optionB";
        this.optionC.type = "optionC";

        this.hitAreaA = this.add.sprite(this.optionA.x, this.optionA.y, "text_hit_area");
        this.hitAreaB = this.add.sprite(this.optionB.x, this.optionB.y, "text_hit_area");
        this.hitAreaC = this.add.sprite(this.optionC.x, this.optionC.y, "text_hit_area");

        this.hitAreaA.setInteractive();
        this.hitAreaB.setInteractive();
        this.hitAreaC.setInteractive();

        this.hitAreaA.on('pointerdown', function () {
            this.onOptionClick(this.optionA);
        }, this);
        this.hitAreaB.on('pointerdown', function () {
            this.onOptionClick(this.optionB);
        }, this);
        this.hitAreaC.on('pointerdown', function () {
            this.onOptionClick(this.optionC);
        }, this);
    },
    onHover: function (gameObject, frame) {
        var str = frame.substr(frame.length - 5, frame.length);
        if (str === "Hover") {
            this.playSound("hoverSound");
        }

        gameObject.setFrame(frame);
    },

    onOptionClick: function (gameObject) {
        if (this.gameOverFlag === true) {
            return;
        }
        if (this.shootedFlag === true) {
            return;
        }

        this.hand.visible = false;

        var optionType = gameObject.type;

        this.shootedFlag = true;
        this.ballDownwardFlag = false;


        var angle = -90;

        this.answer = false;

        var selectedAns = gameObject.id;

        this.selectedOption = gameObject.type;

        if (this.qField.answer === selectedAns) {
            this.answer = true;
        }

        if (gameObject.type === "optionA") {
            angle = -104.68;

            if (this.answer === false) {
                angle = -103.2;
            }
        } else if (gameObject.type === "optionB") {
            angle = -90.5;
            if (this.answer === false) {
                angle = -87.5;
            }

        }
        if (gameObject.type === "optionC") {
            angle = -76;
            if (this.answer === false) {
                angle = -78;
            }
        }


        var angleInRadians = angle * Math.PI / 180;
        this.targetAngle = angleInRadians;

        this.throwing_ball.play();

        this.moveBall(gameObject.type);

    },
    onCloseButtonClick: function () {
        warningState.showWarning(this);
    },

    onBallDown: function (event) {
        this.mouseStartX = event.downX;
        this.mouseStartY = event.downY;

    },
    moveBall: function (type) {
        if (this.gameOverFlag === true) {
            return;
        }

        var scale2 = 0.2;
        var scale3 = 0.28;
        var speed = 18;

        if (type === "optionA" || type === "optionC") {
            scale2 = 0.2;
            scale3 = 0.28;

            if (this.answer === true) {
                speed = 19;
            }
        }

        this.ball.setStatic(false);

        var angleR = this.targetAngle;
        var vx = Math.cos(angleR) * speed;
        var vy = Math.sin(angleR) * speed;

        this.ball.setVelocityX(vx);
        this.ball.setVelocityY(vy);

        if (this.selectedOption === "optionC" && this.answer === false) {
            this.ball.setAngularVelocity(-0.1);
        } else if (this.selectedOption === "optionB" && this.answer === false) {
            this.ball.setAngularVelocity(0.1);
        } else if (this.selectedOption === "optionA") {
            this.ball.setAngularVelocity(-0.1);
        } else {
            this.ball.setAngularVelocity(0.1);
        }

        this.ball.ringFlag = false;

        var tween = this.tweens.add({
            targets: this.ball,
            scaleX: 0.6,
            scaleY: 0.6,
            delay: 0,
            duration: 1000
        });

        this.scaleTween = this.tweens.add({
            targets: this.ball,
            scaleX: scale2,
            scaleY: scale2,
            delay: 1000,
            duration: 200
        });


        this.scaleTween = this.tweens.add({
            targets: this.ball,
            scaleX: scale3,
            scaleY: scale3,
            delay: 300,
            duration: 1000
        });

    },

    update: function () {


        if (this.gameOverFlag === true) {
            return;
        }

        this.qField.text.x = this.ball.x;
        this.qField.text.y = this.ball.y;

        this.qField.text.scaleX = this.ball.scaleX * 2;
        this.qField.text.scaleY = this.ball.scaleY * 2;

        this.qField.sprite.x = this.ball.x;
        this.qField.sprite.y = this.ball.y;

        this.qField.sprite.scaleX = Math.min(1.2, this.ball.scaleX * 2);
        this.qField.sprite.scaleY = Math.min(1.2, this.ball.scaleY * 2);

        if (this.ball.body.velocity.y > 0) {
            this.onDownWard();
        }

    },
    onDownWard: function () {

        /*if(this.ball.soundPlayed === false)
        {
          var dy = Math.abs(this.ball.y-this.ring_1.y);

          console.log("dy: ",dy);

          if(this.answer === true && dy < 150)
          {
            this.ball.soundOnFlag = true;
            this.playSound("win" + Phaser.Math.Between(1, 2));
          }
        }*/

        if (this.ballDownwardFlag === false) {
            this.ballDownwardFlag = true;

            this.ring_1.visible = false;
            this.ring_2.visible = false;
            this.ring_3.visible = false;

            this.ring_1f.visible = true;
            this.ring_2f.visible = true;
            this.ring_3f.visible = true;

            this.changePlatfomPos();

        }

        if (this.ball.y >= 200 && this.ball.ringFlag === false) {
            this.ball.ringFlag = true;

            if (this.answer === true) {
                if (this["prev_win_id"]) {
                    this["prev_win_id"] = this["prev_win_id"] == 1 ? 2 : 1;
                } else {
                    this["prev_win_id"] = 1;
                }
                this.playSound("win" + this["prev_win_id"]);

                this.tallyBallGroup.children.entries[this.qCount].visible = true;

                this.setScore(1);

            } else if (this.answer === false) {
                this.tallyCircleGroup.children.entries[this.qCount].visible = true;

                this.failCount++;
            }
        }

    },
    setScore: function (n) {
        Global.score += n;
    },
    getAngle: function (pointA, pointB) {
        var dx = pointB.x - pointA.x;
        var dy = pointB.y - pointA.y;
        var angle2 = Math.atan2(dy, dx);
        return angle2;
    },
    playSound: function (str) {
        if (this.gameOverFlag === true) {
            return;
        }

        if (Global.soundOnFlag === false) {
            return;
        }

        if (str === "win1") {
            this.winSound1.play();
        } else if (str === "win2") {
            this.winSound2.play();
        } else if (str === "wrong") {
            this.wrongSound.play();
        } else if (str === "clickSound") {
            this.clickSound.play();
        } else if (str === "hoverSound") {
            this.hoverSound.play();
        }

    },

    updateQuestions: function () {
        var options = Global.optionsArr[this.qCount];

        var optionA = options[0];
        var optionB = options[1];
        var optionC = options[2];

        var qfield = Global.questionsArr[this.qCount];

        this.qField.answer = qfield.answer;

        if (qfield.questionImg != "") {
            this.qField.sprite.setTexture("Q" + (this.qCount + 1));
            this.qField.sprite.setVisible(true);
            this.qField.text.setVisible(false);
        } else {
            this.qField.text.setText(qfield.questionTxt);
            this.qField.text.setVisible(true);
            this.qField.sprite.setVisible(false);
        }

        var textStyle = {
            font: "75px QuranEra",
            fill: '#000000',
            fontWeight: 'bold',
            align: 'center',
            stroke: "#ffffff",
            fixedWidth: 180,
            fixedHeight: 170
        };

        if (optionA.img != "") {
            this.optionA.sprite.setTexture("Q" + (this.qCount + 1) + "_op1");
            this.optionA.sprite.setVisible(true);
            this.optionA.text.setVisible(false);
        } else {
            this.optionA.text.setText(optionA.text);
            this.optionA.sprite.setVisible(false);
            this.optionA.text.setVisible(true);
        }

        if (optionB.img != "") {
            this.optionB.sprite.setTexture("Q" + (this.qCount + 1) + "_op2");
            this.optionB.sprite.setVisible(true);
            this.optionB.text.setVisible(false);
        } else {
            this.optionB.text.setText(optionB.text);
            this.optionB.sprite.setVisible(false);
            this.optionB.text.setVisible(true);
        }

        if (optionC.img != "") {
            this.optionC.sprite.setTexture("Q" + (this.qCount + 1) + "_op3");
            this.optionC.sprite.setVisible(true);
            this.optionC.text.setVisible(false);
        } else {
            this.optionC.text.setText(optionC.text);
            this.optionC.sprite.setVisible(false);
            this.optionC.text.setVisible(true);
        }

    },
    nextQuestion: function () {
        if (this.qCount < this.totalQuestions - 1) {
            this.qCount++;
            this.updateQuestions();
        }

    },
    onGameOver: function (str) {
        var self = this;

        this.gameOverFlag = true;

        setTimeout(function () {

            self.bgmusic.stop();

            if (str === "fail") {
                self.scene.start("loose");
            } else if (str === "finished") {
                self.scene.start("win");
            }

        }, 500);
    }
};

var shootingHoops = (json) => {
    Global.json = json;
    Global.actualQCnt = json.data.length;
    let config = {

        type: Phaser.CANVAS,
        transparent: true,
        scale: {
            mode: Phaser.Scale.NONE,
            parent: 'game-holder',
            resolution: 1,
        },
        autoCenter: Phaser.Scale.CENTER_BOTH,

        physics: {
            default: "matter",
            matter: { debug: false },
            audio: { disableWebAudio: true }
        },
        dom: {
            createContainer: true
        }
    };

    if (typeof window.orientation !== 'undefined') {
        config.scale.mode = Phaser.Scale.FIT;
        config.scale.width = 980;
        config.scale.height = 600;
        config.autoCenter = Phaser.Scale.CENTER_BOTH;
        config.scale.resolution = 1;
    } else {
        config.scale.mode = Phaser.Scale.NONE;
        config.scale.width = 980;
        config.scale.height = 600;
        config.autoCenter = Phaser.Scale.CENTER_BOTH;
        config.scale.resolution = 1
    }

    game = new Phaser.Game(config);
    game.scene.add("boot", bootState);
    game.scene.add("preload", preloadState);

    game.scene.add("welcome", welcomeState);
    game.scene.add("win", winState);
    game.scene.add("loose", looseState);
    game.scene.add("warning", warningState);
    game.scene.add("game", gameState);
    game.scene.start("boot");

};

var Global = {};
Global.questionsArr = [];
Global.optionsArr = [];
Global.soundOnFlag = true;
Global.score = 0;

function send(json, callback) {
    onSuccess(json, callback);
}

function onSuccess(res, callback) {
    var arr = res.data;
    Global.questionsArr = [];
    Global.optionsArr = [];

    for (var i = 0; i < arr.length; i++) {
        Global.questionsArr.push(arr[i]);
        Global.optionsArr.push(arr[i].options);
    }

    callback();
}


function resize() {
    var canvas = document.querySelector("canvas");
    var windowWidth = window.innerWidth;
    var windowHeight = window.innerHeight;
    var windowRatio = windowWidth / windowHeight;
    var gameRatio = 980 / 600;

    if (windowRatio < gameRatio) {
        canvas.style.width = windowWidth + "px";
        canvas.style.height = (windowWidth / gameRatio) + "px";
    }
    else {
        canvas.style.width = 980;//(windowHeight * gameRatio) + "px";
        canvas.style.height = 600;//windowHeight + "px";
    }
}


var destroyShootingHoops = () => {
    if (game) {
        game.destroy();
    }

}
